import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store/store";
import axios from "axios";

import Home from '../views/Home.vue'
import Users from '../views/Users.vue'
import User from '../views/User.vue'
import UserPermission from '../views/UserPermission.vue'
import Clients from '../views/Clients.vue'
import Client from '../views/Client.vue'
import Login from '../views/Login.vue'
import BillingPrices from '../views/BillingPrices.vue'
import PayrollPrices from '../views/PayrollPrices.vue'
import BillingPrice from '../views/BillingPrice.vue'
import PayrollPrice from '../views/PayrollPrice.vue'
import BillingParameter from '../views/BillingParameter.vue'
import PayrollParameter from '../views/PayrollParameter.vue'
import Integrations from '../views/Integrations.vue'
import Billings from '../views/Billings.vue'
import Billing from '../views/Billing.vue'
import Payroll from '../views/Payroll.vue'
import Payrolls from '../views/Payrolls.vue'
import Broker from '../views/Broker.vue'
import Brokers from '../views/Brokers.vue'
import Contact from '../views/Contact.vue'
import Contacts from '../views/Contacts.vue'
import Page404 from '../views/Page404.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/billing/parameter',
        name: 'BillingParameter',
        component: BillingParameter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/payroll/parameter',
        name: 'PayrollParameter',
        component: PayrollParameter,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/billing/prices',
        name: 'BillingPrices',
        component: BillingPrices,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/billing/price/:id?',
        name: 'BillingPrice',
        component: BillingPrice,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/payroll/prices',
        name: 'PayrollPrices',
        component: PayrollPrices,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/payroll/price/:id?',
        name: 'PayrollPrice',
        component: PayrollPrice,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/user/:id?',
        name: 'User',
        component: User,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/user/:id?/permission',
        name: 'UserPermission',
        component: UserPermission,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/integrations',
        name: 'Integrations',
        component: Integrations,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/billings',
        name: 'Billings',
        component: Billings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/billing',
        name: 'NewBilling',
        component: Billing,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/billing/:id?',
        name: 'Billing',
        component: Billing,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/payrolls',
        name: 'Payrolls',
        component: Payrolls,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/payroll',
        name: 'Payroll',
        component: Payroll,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/payroll/:id?',
        name: 'Payroll',
        component: Payroll,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/clients',
        name: 'Clients',
        component: Clients,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/client/:id?',
        name: 'Client',
        component: Client,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/brokers',
        name: 'Brokers',
        component: Brokers,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/broker/:id?',
        name: 'Broker',
        component: Broker,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/contact/:id?',
        name: 'Contact',
        component: Contact,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/*',
        name: 'Page404',
        component: Page404
    }
]

export const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    if (to.path != '/') {
        store.dispatch('setAccessedUrl', to.path);
    }

    if (store.getters.isAuthenticated == null) {
        axios.get('/session').then((response) => {
                store.commit('setPermission', response.data.scope)

                var filter = "/findByUsername";
                var paramValue = {
                    "username": response.data.username,
                };
                axios.get("user" + filter, {
                    params: paramValue
                }).then((logUser) => {
                    store.commit('setUser', logUser.data[0].data)
                    store.commit('setAuthenticated', true)
                    store.commit('setToken', logUser.headers.token)
                    if (to.path == '/') {
                        next('/home')
                        return
                    }
                    next()
                    return
                })
            })
            .catch((error) => {
                console.log(error)
                store.commit('setAuthenticated', false)
                next('/')
                return
            })
    } else {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            if (store.getters.isAuthenticated) {
                next()
                return
            }
            next('/')
        }
        next()
    }

})

export default router