<template>
    <div class="m-5">
        <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
              {{ feedbackMessage }}
            </b-alert>
        </div>
        <b-container fluid class="mb-3">
            <b-row align-h="start">
                <b-col class="pl-0 pr-2" md="auto">
                    <h3>Permissions for <b-link :href="userProfileUrl">{{ first_name + " " + last_name }}</b-link></h3>
                </b-col>
                <b-col cols="7" class="p-0">
                    <b-button pill class="ml-2" variant="outline-primary" size="sm" to="/users/" ref="user">
                        <b-icon icon="arrow-return-left"></b-icon>
                        Go Back
                    </b-button>
                    <b-button class="ml-2" pill variant="outline-primary" size="sm" to="/home/" ref="home">
                        <b-icon icon="house"></b-icon>
                        Home
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
        <b-overlay :show="loadingData" rounded="sm">
            <b-form v-if="show">
                <b-container fluid>
                    <b-row>
                        <b-col cols="12">
                            <b-row v-for="item in permissionGroupArray" :key="item.id">
                                <PermissionGroupByUser :permissionGroupId="item.id" :userId="userId"></PermissionGroupByUser>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container class="mt-4" fluid>
                    <b-row>
                        <b-col>
                            <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                                <b-button class="mr-2" type="button" variant="primary" :disabled="loading" ref="button" @click.prevent="onSubmit()">
                                    <b-icon icon="file-earmark"></b-icon>
                                    Save
                                </b-button>
                            </b-overlay>
                            <b-button variant="outline-primary" to="/users/" ref="user">
                                <b-icon icon="arrow-return-left"></b-icon>
                                Go Back
                            </b-button>
                        </b-col>
                        <b-col class="text-right">
                            <b-button class="mr-2" type="reset" variant="outline-danger" @click.prevent="onReset()">
                                <b-icon icon="arrow-counterclockwise"></b-icon>
                                Reset
                            </b-button>
                            <b-button type="button" variant="danger" @click.prevent="deleteRow()">
                                <b-icon icon="trash"></b-icon>
                                Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </b-form>
        </b-overlay>
    </div>
</template>
  
<script>
import axios from 'axios'
import Feedback from '../mixins/Feedback.js'
import PermissionGroupByUser from '../components/PermissionGroupByUser.vue'
export default {
    mixins: [Feedback],
    components: {
        PermissionGroupByUser
    },
    data() {
        return {
            userProfileUrl: null,
            show: true,
            loading: false,
            endpoint: 'user',
            permissionGroupArray: null,
            loadingData: false,
            userId: null,
            first_name: null,
            last_name: null
        }
    },
    methods: {
        async getData(){
            this.userId = this.$route.params.id
            if(this.userId){
                try {
                    this.loadingData = true
                    var getEndpoint = this.endpoint + "/" + this.$route.params.id;
                    let user = await axios.get(getEndpoint)
                    this.userProfileUrl = user.data[1].links.self_pattern
                    this.first_name = user.data[0].data.first_name
                    this.last_name = user.data[0].data.last_name

                    var getPermissionGroupEndpoint = "permissiongroup/findAll"
                    let permissionGroup = await axios.get(getPermissionGroupEndpoint)
                    this.permissionGroupArray = permissionGroup.data.data
                    this.loadingData = false

                } catch(error){
                    console.log(error)
                    this.loadingData = false
                    this.errorAlert(error);
                }
            }
        }
    },
    created(){
        this.getData()
    }
}
</script>