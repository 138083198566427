<template>
    <div v-can="'Read_Payroll'">
        <div class="m-5">
            <b-container fluid>
                <b-row>
                    <b-col class="pl-0 pr-2" md="auto">
                        <h3>Payroll</h3>
                    </b-col>
                    <b-col cols="4" class="p-0">
                        <b-button pill variant="primary" size="sm" to="/payroll/" ref="payroll" v-can="'Create_Payroll'">
                            <b-icon icon="plus-circle"></b-icon>
                            New
                        </b-button>
                        <b-button class="ml-2" pill variant="outline-primary" size="sm" to="/home/" ref="home">
                            <b-icon icon="house"></b-icon>
                            Home
                        </b-button>
                    </b-col>
                    <b-col class="p-0">
                        <b-input-group size="sm" class="mb-2">
                            <b-form-datepicker id="from-date" v-model="start_date" class="m-2" @input="loadGridData()"></b-form-datepicker>
                            <b-icon icon="arrow-left-square" variant="info" class="mt-3"></b-icon>&nbsp;&nbsp;
                            <b-icon icon="arrow-right-square" variant="info" class="mt-3"></b-icon>
                            <b-form-datepicker id="to-date" v-model="end_date" class="m-2" @input="loadGridData()"></b-form-datepicker>
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-container>
            <b-table striped hover :items="payrolls" :fields="fields" :filter="filter" :per-page="perPage" sort-icon-left :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" @sort-changed="loadGridData()">
                <template #cell(end_date)="data">
                    {{filterOnlyDate(data.item.end_date)}}
                </template>
                <template #cell(start_date)="data">
                    {{filterOnlyDate(data.item.start_date)}}
                </template>
                <template #cell(surcharge)="data">
                    {{data.item.surcharge}}
                </template>
                <template #cell(output_file)="data">
                    <span v-show="data.item.output_file">
                        <b-button @click="downloadItem('/payroll/download/'+data.item.id+'/excel')" variant="link">
                            <b-img src="../assets/excel.png" alt="Transport Regent"></b-img>
                        </b-button>
                    </span>
                </template>
                <template #cell(edit)="data">
                    <b-button variant="link" :to="data.item.links.self_pattern" ref="payroll" v-can="'Update_Payroll'">
                        <b-icon icon="pencil-square" variant="info"></b-icon>
                    </b-button>
                </template>
                <template #cell(delete)="data">
                    <b-button variant="link" @click="deleteRow(data.item.links.self_pattern)" v-can="'Delete_Payroll'">
                        <b-icon icon="x-octagon-fill" variant="danger"></b-icon>
                    </b-button>
                </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="payrolls-table" @input="loadGridData()"></b-pagination>
        </div>
        <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                {{ feedbackMessage }}
            </b-alert>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Basic from '../mixins/Basic.js'
import Feedback from '../mixins/Feedback.js'

export default {
    name: 'Home',
    mixins: [Feedback, Basic],
    data() {
        return {
            currentPage: 1,
            rows: 1,
            perPage: 5,
            start_date: '',
            end_date: '',
            payrolls: null,
            filter: '',
            sortBy: 'start_date',
            sortDesc: true,
            fields: [
                {
                    key: 'start_date',
                    sortable: true,
                    label: 'Start Date'
                },
                {
                    key: 'end_date',
                    sortable: true,
                    label: 'End Date'
                },
                {
                    key: 'total',
                    sortable: true,
                    label: 'Total (CAD)',
                    formatter: (value) => {
                       return Number(value).toLocaleString('en-CA', {minimumFractionDigits: 2, style: 'decimal', currency: 'CAD'})
                    }
                },
                {
                    key: 'output_file',
                    sortable: false,
                    label: 'File'
                },
                {
                    key: 'edit',
                    sortable: false,
                    label: 'Edit'
                },
                {
                    key: 'delete',
                    sortable: false,
                    label: 'Delete'
                }
            ]
        };
    },
    components: {

    },
    methods: {
        filterOnlyDate(date){
            var datearray = date.split(' ')
            return datearray[0];
        },
        async loadGridData(){
            try {
                if(this.sortBy==''||this.sortBy==null) this.sortBy = 'start_date'
                var sortDirection = 'asc';
                if(this.sortDesc) sortDirection = 'desc';
                let response = await axios.get('/payroll?start_date='+this.start_date+'&end_date='+this.end_date+'&orderBy='+this.sortBy+','+sortDirection+'&pageNumber='+this.currentPage+'&size='+this.perPage);
                this.payrolls = response.data.data
                this.rows = response.data.page.totalElements
            } catch (error) {
                this.payrolls = []
                console.log(error)
            }

        },
        deleteRow(id){
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(async value => {
                    if(value){
                        try {
                            var response = await axios.delete(id)
                            if(response.status==204){
                                this.loadGridData()
                                this.succesAlert()
                            }
                        } catch (error) {
                            this.errorAlert(error);
                            console.log(error)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    },
    created(){
        var today = new Date();
        this.start_date = today.getFullYear()+'-01-01';
        this.end_date = today.getFullYear()+'-12-31';
        this.loadGridData()
    }
}
</script>
<style scoped>

</style>