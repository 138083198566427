import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import store from './store/store'

//axios config
axios.defaults.baseURL = location.origin + "/api/";
axios.defaults.withCredentials = true;
axios.interceptors.response.use(response => {
  return response;
}, error => {
    if (error.response.status === 401 && router.history.current.path != "/") {
        axios.defaults.withCredentials = false;
        router.push("/");
    }
    return Promise.reject(error);
});

Vue.directive('can', {
    bind: function(el, binding, vnode) {
        if(store.getters.getPermission.grants!='root'){
            var control = false;
            var expression = binding.value.replace(/[']/g, "")
            var arrayOfVCanDirective = expression.split('||')
            arrayOfVCanDirective.forEach(bindingElement => {
                store.getters.getPermission.permissions.forEach(permissionElement => {
                    if(bindingElement==permissionElement){
                        control = true;
                        return;
                    }
                });
            }); 

            if (!control) {
                if(binding.arg == "input"){
                    vnode.elm.disabled = true;
                }
                else if(binding.arg == "datepicker"){
                    vnode.elm.setAttribute(":disabled", true);
                    vnode.elm.setAttribute("aria-disabled", true);
                }
                else if(binding.arg == "multiselect"){
                    vnode.elm.classList.add('multiselect--disabled');
                }
                else if(binding.arg == "textarea"){
                    vnode.elm.setAttribute("disabled", true);
                }
                else if(binding.arg == "file" || binding.arg == "number" || binding.arg == "checkbox"){
                    vnode.elm.getElementsByTagName("input")[0].setAttribute("disabled", true);
                }
                else{
                    vnode.elm.style.display = "none";
                }
            }
        }
    }
})

import {BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
