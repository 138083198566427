<template>
    <div v-can="'Read_DataIntegration'">
        <div class="m-5">
            <b-container fluid>
                <b-row>
                    <b-col class="pl-0 pr-2" md="auto">
                        <h3>Integration from StreetSync</h3>
                    </b-col>
                    <b-col cols="4" class="p-0">
                        <b-button pill variant="primary" size="sm" v-b-modal.upload v-can="'Create_DataIntegration'">
                            <b-icon icon="plus-circle"></b-icon>
                            New
                        </b-button>
                        <b-button class="ml-2" pill variant="outline-success" size="sm" @click="loadGridData()">
                            <b-icon icon="arrow-repeat"></b-icon>
                            Refresh
                        </b-button>
                        <b-button class="ml-2" pill variant="outline-primary" size="sm" to="/home/" ref="home">
                            <b-icon icon="house"></b-icon>
                            Home
                        </b-button>
                    </b-col>
                    <b-col class="p-0">
                        <b-input-group size="sm" class="mb-2">
                            <b-form-datepicker id="from-date" v-model="start_date" class="m-2" @input="loadGridData()"></b-form-datepicker>
                            <b-icon icon="arrow-left-square" variant="info" class="mt-3"></b-icon>&nbsp;&nbsp;
                            <b-icon icon="arrow-right-square" variant="info" class="mt-3"></b-icon>
                            <b-form-datepicker id="to-date" v-model="end_date" class="m-2" @input="loadGridData()"></b-form-datepicker>
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-container>
            <b-table striped hover :items="integrations" :fields="fields" :filter="filter" :per-page="perPage" sort-icon-left :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" @sort-changed="loadGridData()">
                <template #cell(notes)="data">
                    <span><span v-if="data.item.notes">{{data.item.notes.substring(0,30)}}&nbsp;</span>
                        <b-button size="sm" variant="link" v-b-modal.editNotes @click="getNotes(data.item.links.self_pattern, data.item.notes)" class="noLinkStyle">
                            <b-icon icon="pencil-square"></b-icon>
                        </b-button>
                    </span>
                </template>
                <template #cell(delivery_date)="data">
                    {{filterOnlyDate(data.item.delivery_date)}}
                </template>
                <template #cell(addDate)="data">
                    <b-link href="#" @click="downloadItem('/integration/streetsync/'+data.item.id+'/csv')">
                        {{filterOnlyDate(data.item.addDate)}} at {{filterOnlyHour(data.item.addDate)}}
                    </b-link>
                </template>
                <template #cell(download)="data">
                    <b-button @click="downloadAllFiles(data.item.id)" variant="link">
                        <b-img src="../assets/excel.png" alt="Transport Regent"></b-img>
                    </b-button>
                </template>
                <template #cell(show_details)="row">
                    <b-button size="sm" @click="showDetails(row)" v-b-modal.history class="mr-2" variant="link">
                        <b-icon icon="eye" variant="dark"></b-icon>
                    </b-button>
                </template>
                <template #cell(edit)="data">
                    <b-button variant="link" :to="data.item.links.self_pattern" ref="integration">
                        <b-icon icon="pencil-square" variant="info"></b-icon>
                    </b-button>
                </template>
                <template #cell(delete)="data">
                    <b-button variant="link" @click="deleteRow(data.item.links.self_pattern)" v-can="'Delete_DataIntegration'">
                        <b-icon icon="x-octagon-fill" variant="danger"></b-icon>
                    </b-button>
                </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="integrations-table" @input="loadGridData()"></b-pagination>
        </div>
        <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                {{ feedbackMessage }}
            </b-alert>
        </div>
        <b-modal id="upload" size="lg" title="Upload StreetSync File" ok-title="Import" cancel-title="Close" @ok="handleOk">
            <b-overlay :show="loadingData" rounded="sm">
                Select a valid file exported from StreetSync (.csv format)
                <b-form-file
                v-model="uploadFile"
                :state="Boolean(uploadFile)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                >
                </b-form-file>
                <span class="red">{{log}}</span>
            </b-overlay>
        </b-modal>
        <b-modal id="editNotes" size="lg" title="Editing notes" ok-title="Save" cancel-title="Close" @ok="handleSaveNotes">
            <b-form-textarea id="textarea" v-model="notesTemp" rows="3" max-rows="6"></b-form-textarea>
        </b-modal>
        <b-modal id="history" size="lg" title="History" cancel-title="Close" ok-only>
            <b-table small :items="detailsData" :fields="detailsFields" head-variant="light" :hover="false" table-variant="light">
                <template #cell(date_time)="data">
                    {{filterOnlyDate(data.item.date_time)}} at {{filterOnlyHour(data.item.date_time)}}
                </template>
            </b-table>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios'
import Basic from '../mixins/Basic.js'
import Feedback from '../mixins/Feedback.js'

export default {
    name: 'Home',
    mixins: [Feedback, Basic],
    data() {
        return {
            loadingData: false,
            currentPage: 1,
            rows: 1,
            perPage: 5,
            start_date: '',
            end_date: '',
            integrations: null,
            uploadFile: null,
            log: '',
            idEditNotes: '',
            notesTemp: '',
            filter: '',
            sortBy: 'addDate',
            sortDesc: true,
            form: {
                notes: ''
            },
            detailsData: [],
            fields: [
                {
                    key: 'addDate',
                    sortable: true,
                    label: 'Creation'
                },
                {
                    key: 'notes',
                    sortable: false,
                    label: 'Notes'
                },
                {
                    key: 'delivery_date',
                    sortable: true,
                    label: 'Delivery Date'
                },
                {
                    key: 'routes',
                    sortable: false,
                    label: 'Routes'
                },
                {
                    key: 'number_of_deliveries',
                    sortable: true,
                    label: 'Deliveries',
                    formatter: (value) => {
                       return Number(value).toLocaleString('en-CA', {minimumFractionDigits: 0, style: 'decimal', currency: 'CAD'})
                    }
                },
                {
                    key: 'download',
                    sortable: false,
                    label: 'Download'
                },
                {
                    key: 'show_details',
                    sortable: false,
                    label: 'History'
                },
                {
                    key: 'delete',
                    sortable: false,
                    label: 'Delete'
                }
            ],
            detailsFields: [
                {
                    key: 'description',
                    sortable: false,
                    label: 'Description'
                },
                {
                    key: 'date_time',
                    sortable: false,
                    label: 'Date / Time'
                },
                {
                    key: 'login_user',
                    sortable: false,
                    label: 'Username'
                }
            ]
        }
    },
    components: {

    },
    methods: {
        async showDetails(row){
            var temp = await axios.get("integration/"+row.item.id+"/history")
            this.detailsData = temp.data.data
        },
        downloadAllFiles(id){
            this.downloadItem('/integration/times/'+id+'/excel')
            this.downloadItem('/integration/prog/'+id+'/excel')
            this.downloadItem('/integration/ss/'+id+'/excel')
        },
        getNotes(id, notes){
            var idArr = id.split('/')
            this.idEditNotes = idArr[2]
            this.notesTemp = notes
        },
        async handleSaveNotes(bvModalEvent){
            bvModalEvent.preventDefault()
            this.form.notes = this.notesTemp
            await axios.put( '/integration/notes/'+this.idEditNotes, this.form);
            this.$bvModal.hide('editNotes')
            this.loadGridData()
        },
        filterOnlyDate(date){
            var datearray = date.split(' ')
            return datearray[0];
        },
        filterOnlyHour(date){
            var datearray = date.split(' ')
            var datearray2 = datearray[1].split(':')
            return datearray2[0]+":"+datearray2[1];
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.importFile()
        },
        async importFile(){
            try {
                this.loadingData = true;
                let formData = new FormData();
                formData.append('file', this.uploadFile);

                await axios.post( '/integration/import',
                    formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                    }
                );

                this.$bvModal.hide('upload')
                this.loadGridData()
                this.loadingData = false;
            } catch (error) {
                this.log = error.response.data.error;
                this.loadingData = false;
            }
        },
        async loadGridData(){
            try {
                if(this.sortBy==''||this.sortBy==null) this.sortBy = 'delivery_date'
                var sortDirection = 'asc';
                if(this.sortDesc) sortDirection = 'desc';
                let response = await axios.get('/integration?start_date='+this.start_date+'&end_date='+this.end_date+'&orderBy='+this.sortBy+','+sortDirection+'&pageNumber='+this.currentPage+'&size='+this.perPage);
                this.integrations = response.data.data
                this.rows = response.data.page.totalElements
            } catch (error) {
                this.integrations = []
                console.log(error)
            }

        },
        deleteRow(id){
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(async value => {
                    if(value){
                        try {
                            var response = await axios.delete(id)
                            if(response.status==204){
                                this.loadGridData()
                                this.succesAlert()
                            }
                        } catch (error) {
                            this.errorAlert(error);
                            console.log(error)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    },
    created(){
        var today = new Date();
        this.start_date = today.getFullYear()+'-01-01';
        this.end_date = today.getFullYear()+'-12-31';
        this.loadGridData()
    }
}
</script>
<style scoped>
.red{
    font-size: small;
    color: red;
}
.noLinkStyle{
    text-decoration: none;
    color: black;
}
</style>