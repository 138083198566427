<template>
    <div>
        <b-navbar type="light" variant="light" v-if="$store.getters.isAuthenticated && this.$route.name != 'Login' && this.$route.name != 'Page404' ">
            <b-navbar-brand>
                <router-link class="nav-link" to="/home" ref="home"><img src="../assets/logo.png" class="logo" /></router-link>
            </b-navbar-brand>
            <b-navbar-nav class="ml-auto">
                <b-navbar-nav right>
                    <b-nav-text class="mr-3 mt-1 text-danger text-uppercase font-weight-bold">
                        {{env}}
                    </b-nav-text>
                    <b-nav-item-dropdown no-caret right>
                        <template slot="button-content">
                            <router-link class="dropdown-toggle" to="" ref="user">
                                <b-avatar variant="light" size="2em" :src="require(`@/assets/avatar/unknown.png`)" ></b-avatar>
                            </router-link>
                        </template>
                        <b-dropdown-item v-if="$store.getters.getUser.root=='t'" to="/users">
                            Users 
                        </b-dropdown-item>
                        <b-dropdown-item @click.prevent="logout">
                            <a class="text-danger text-decoration-none">Log Out</a>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    name: 'NavBar',
    data() {
        return {
            env: this.setEnv()
        };
    },
    components: {

    },
    methods: {
        setEnv() {
            if (process.env.NODE_ENV != 'production') {
                return "development environment";
            } else {
                return "";
            }
        },
        logout() {
            var self = this;
            axios.post('/logout').then(function() {
                axios.defaults.withCredentials = false;
                self.$store.dispatch('setAccessedUrl', "/");
                self.$store.dispatch('logOut')
                self.$store.commit('setCredentialIncorrect', false)
                self.$router.push("/");
            })
        }
    }
};
</script>

<style scoped>
.logo {
    height: 35px;
}
</style>
