import Vuex from 'vuex'
import Vue from 'vue'

import auth from './modules/auth';
import status from './modules/status';

Vue.use(Vuex)

export default new Vuex.Store({

    modules: {
        auth,
        status
    },

    state: {
    },

    getters: {

    },

    mutations: {

    },

    actions: {

    }
});
