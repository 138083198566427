import axios from "axios";
import {
    router
} from "../../router/index.js"

const state = {
    accessedUrl: "",

    credentialIncorrect: false,

    authenticated: null,

    user: null,

    permission: null,

    token: null

};
const getters = {
    getAccessedUrl: state => {
        return state.accessedUrl
    },

    isAuthenticated: state => state.authenticated,

    isCredentialIncorrect: state => !!state.credentialIncorrect,

    getUser: state => state.user,

    getPermission: state => state.permission,

    getToken: state => state.token

};
const actions = {
    setAccessedUrl(context, url) {
        context.commit("setAccessedUrl", url);
    },


    async logIn({
        commit,
        dispatch
    }, user) {

        try {
            let response = await axios.get('/auth', {
                headers: {
                    auth: btoa(user.username + '|' + user.password)
                }
            })

            await commit('setPermission', response.data.scope)

            let logUser = await axios.get("user/findByUsername?username="+user.username)

            await commit('setUser', logUser.data[0].data)

            await commit('setAuthenticated', true)

            await commit('setToken', response.data.token)

            dispatch('redirect', state.accessedUrl)

        } catch (error) {
            console.log(error)
            commit('setCredentialIncorrect', true)
            axios.defaults.withCredentials = false;
        }
    },

    async logOut({
        commit
    }) {
        commit('logout')
    },

    redirect(context, url) {
        if (url == "" || url == null || url == "/") {
            router.push("/home");
        } else {
            router.push(url);
        }
    }

};
const mutations = {
    setAccessedUrl(state, value) {
        state.accessedUrl = value;
    },

    setUser(state, user) {
        state.user = user
    },

    setPermission(state, permission) {
        state.permission = permission
    },

    setCredentialIncorrect(state, value) {
        state.credentialIncorrect = value
    },

    setAuthenticated(state, value) {
        state.authenticated = value
    },

    logout(state) {
        state.user = null
        state.authenticated = false
        state.token = null
        state.permission = null
    },

    setToken(state, value){
        state.token = value;
    }

};
export default {
    state,
    getters,
    actions,
    mutations
};
