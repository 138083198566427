<template>
    <div v-if="$store.getters.getUser.root=='t'">
        <div class="m-5">
            <b-container fluid>
                <b-row align-h="start">
                    <b-col class="pl-0 pr-2" md="auto">
                        <h3>Users</h3>
                    </b-col>
                    <b-col cols="7" class="p-0">
                        <b-button pill variant="primary" size="sm" to="/user/" ref="user">
                            <b-icon icon="plus-circle"></b-icon>
                            Add
                        </b-button>
                        <b-button class="ml-2" pill variant="outline-primary" size="sm" to="/home/" ref="home">
                            <b-icon icon="house"></b-icon>
                            Home
                        </b-button>
                    </b-col>
                    <b-col class="p-0">
                        <b-input-group size="sm" class="mb-2">
                            <b-input-group-prepend is-text>
                                <b-icon icon="search"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input v-model="filter" type="search" placeholder="Search by username" @input="loadGridData()"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-container>
            <b-table striped hover :items="users" :fields="fields" :filter="filter" :per-page="perPage" sort-icon-left :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" @sort-changed="loadGridData()">
                <template #cell(root)="data">
                    <span class="root-bg" v-if="data.item.root=='t'"><strong>root</strong></span>
                    <span class="user-bg" v-if="data.item.root=='f'"><strong>user</strong></span>
                </template>    
                <template #cell(permission)="data">
                    <b-button v-if="data.item.root!='t'" variant="link" :to="data.item.links.self_pattern+'/permission'" ref="user">
                        <b-icon icon="key-fill" variant="success"></b-icon>
                    </b-button>
                </template>
                <template #cell(edit)="data">
                    <b-button variant="link" :to="data.item.links.self_pattern" ref="user">
                        <b-icon icon="pencil-square" variant="info"></b-icon>
                    </b-button>
                </template>
                <template #cell(delete)="data">
                    <b-button v-if="$store.getters.getUser.username!=data.item.username" variant="link" @click="deleteRow(data.item.links.self_pattern)">
                        <b-icon icon="x-octagon-fill" variant="danger"></b-icon>
                    </b-button>
                </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="users-table" @input="loadGridData()"></b-pagination>
        </div>
        <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                {{ feedbackMessage }}
            </b-alert>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Feedback from '../mixins/Feedback.js'

export default {
    name: 'Home',
    mixins: [Feedback],
    data() {
        return {
            currentPage: 1,
            rows: 1,
            perPage: 5,
            filter: '',
            users: null,
            sortBy: 'username',
            sortDesc: false,
            fields: [
                {
                    key: 'username',
                    sortable: true,
                    label: 'Username'
                },
                {
                    key: 'first_name',
                    sortable: true,
                    label: 'First Name'
                },
                {
                    key: 'last_name',
                    sortable: true,
                    label: 'Last Name'
                },
                {
                    key: 'root',
                    sortable: false,
                    label: 'Root'
                },
                {
                    key: 'permission',
                    sortable: false,
                    label: 'Permissions'
                },
                {
                    key: 'edit',
                    sortable: false,
                    label: 'Edit'
                },
                {
                    key: 'delete',
                    sortable: false,
                    label: 'Delete'
                }
            ]
        };
    },
    components: {

    },
    methods: {
        async loadGridData(){
            try {
                var sortDirection = 'asc';
                if(this.sortDesc) sortDirection = 'desc';
                let response = await axios.get('/user?username='+this.filter+'&orderBy='+this.sortBy+','+sortDirection+'&pageNumber='+this.currentPage+'&size='+this.perPage);
                this.users = response.data.data
                this.rows = response.data.page.totalElements

            } catch (error) {
                this.users = []
                console.log(error)
            }

        },
        deleteRow(id){
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(async value => {
                    if(value){
                        try {
                            var response = await axios.delete(id)
                            if(response.status==204){
                                this.loadGridData()
                                this.succesAlert()
                            }
                        } catch (error) {
                            this.errorAlert(error);
                            console.log(error)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    },
    created(){
        this.loadGridData()
    }
}
</script>
<style scoped>
.root-bg{
    color: rgb(197, 0, 0);
    font-variant: small-caps;
    letter-spacing: 4.2px;
}

.user-bg{
    color: rgb(0, 144, 180);
    font-variant: small-caps;
    letter-spacing: 4.2px;
}
</style>