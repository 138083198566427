<template>
    <div v-can="'Read_Brokers'" class="m-5">
      <div class="fixed-top mt-3 d-flex justify-content-center">
          <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
              {{ feedbackMessage }}
          </b-alert>
      </div>
      <b-container fluid>
          <b-row align-h="start">
              <b-col class="pl-0 pr-2" md="auto">
                  <h3>Broker</h3>
              </b-col>
              <b-col cols="7" class="p-0">
                  <b-button pill variant="outline-primary" size="sm" to="/brokers/" ref="broker">
                      <b-icon icon="arrow-return-left"></b-icon>
                      Go Back
                  </b-button>
                  <b-button class="ml-2" pill variant="outline-primary" size="sm" to="/home/" ref="home">
                      <b-icon icon="house"></b-icon>
                      Home
                  </b-button>
              </b-col>
          </b-row>
      </b-container>
      <b-overlay :show="loadingData" rounded="sm">
          <b-form v-if="show">
            <b-card bg-variant="light" sub-title="Broker Identification" class="mt-3">
                <b-container fluid>
                    <b-row>
                        <b-col>
                            <b-form-group id="input-group-nickname" label="Nickname:" label-for="input-nickname" description="Minimal 1 alphanumeric characters.">
                                <b-form-input id="input-nickname" v-model="form.nickname" type="text" placeholder="Enter nickname" :state="validateState('nickname')" aria-describedby="nickname-live-feedback" v-can:input="editionPermission"></b-form-input>
                                <b-form-invalid-feedback id="nickname-live-feedback">This is a required field (minimal 1 character).</b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group id="input-group-name" label="Business Name:" label-for="input-name" description="Minimal 1 alphanumeric characters.">
                                <b-form-input id="input-name" v-model="form.name" type="text" placeholder="Enter business name" :state="validateState('name')" aria-describedby="name-live-feedback" v-can:input="editionPermission"></b-form-input>
                                <b-form-invalid-feedback id="name-live-feedback">This is a required field (minimal 1 character).</b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group id="input-group-email_address" label="E-mail address:" label-for="input-email_address">
                                <b-form-input id="input-email_address" v-model="form.email_address" type="text" placeholder="Enter e-mail address" :state="validateState('email_address')" aria-describedby="email_address-live-feedback" v-can:input="editionPermission"></b-form-input>
                                <b-form-invalid-feedback id="email_address-live-feedback">Use a valid e-mail address.</b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group id="input-group-phone_number" label="Phone Number:" label-for="input-phone_number">
                                <b-form-input id="input-phone_number" v-model="form.phone_number" type="text" placeholder="Enter phone number" v-can:input="editionPermission"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group id="input-group-fax_number" label="Fax Number:" label-for="input-fax_number">
                                <b-form-input id="input-fax_number" v-model="form.fax_number" type="text" placeholder="Enter fax number" v-can:input="editionPermission"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group id="input-group-address" label="Address:" label-for="input-address">
                                <b-form-input id="input-address" v-model="form.address" type="text" placeholder="Enter address" v-can:input="editionPermission"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group id="input-group-city" label="City:" label-for="input-city">
                                <b-form-input id="input-city" v-model="form.city" type="text" placeholder="City" v-can:input="editionPermission"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group id="input-group-state" label="State:" label-for="input-state">
                                <b-form-input id="input-state" v-model="form.state" type="text" placeholder="Enter state" v-can:input="editionPermission"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group id="input-group-postal_code" label="Postal code:" label-for="input-postal_code">
                                <b-form-input id="input-postal_code" v-model="form.postal_code" type="text" placeholder="Enter postal code" v-can:input="editionPermission"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-container>
            </b-card>
            <b-card bg-variant="light" sub-title="Cigo Identification" class="mt-3">
                <b-container fluid>
                    <b-row>
                        <b-col>
                            <b-form-group id="input-group-cigoid" label="Cigo ID:" label-for="input-cigoid" description="Minimal 1 alphanumeric characters.">
                                <b-form-input id="input-cigoid" v-model="form.cigoid" type="text" placeholder="Enter Cigo ID" v-can:input="editionPermission"></b-form-input>
                                <b-form-invalid-feedback id="cigoid-live-feedback">This is a required field (minimal 1 character).</b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-container>
            </b-card>

            <b-modal id="upload" size="lg" title="Upload files to broker file" ok-title="Upload" cancel-title="Close" @ok="handleOk">
                <p class="text-info smaller"><strong>Formats accepted: </strong>csv, pdf, jpg, jpeg, png, gif, tiff, txt, doc, docx, xls, xlsx.</p>
                <p class="text-info smaller"><strong>Maximum file size: </strong>10 MB.</p>
                <b-overlay rounded="sm">
                    <b-form-file
                    v-model="uploadFileSelected"
                    :state="Boolean(uploadFileSelected)"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    >
                    </b-form-file>
                    <span class="red">{{log}}</span>
                </b-overlay>
            </b-modal>

            <b-card bg-variant="light" sub-title="Broker Files" class="mt-3" v-if="!loading && this.$route.params.id">
                <b-container fluid class="px-0">
                    <b-row align-v="center" class="mb-2 mt-3 px-0">
                        <b-col>
                            <b-button pill variant="primary" size="sm" v-b-modal.upload v-can="editionPermission">
                                <b-icon icon="cloud-upload"></b-icon>
                                Upload
                            </b-button>
                        </b-col>
                        <b-col>
                            <b-input-group size="sm" class="mb-2">
                            <b-input-group-prepend is-text>
                                <b-icon icon="search"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input v-model="fileFilter" type="search" placeholder="Search by file name" @input="loadGridFiles()"></b-form-input>
                        </b-input-group>
                        </b-col>
                    </b-row>
                </b-container>


                <b-table striped hover :items="brokerFiles" :fields="fileFields" :filter="fileFilter" :per-page="filePerPage" sort-icon-left :sort-by.sync="fileSortBy" :sort-desc.sync="fileSortDesc" :no-local-sorting="true" @sort-changed="loadGridFiles()">
                    <template #cell(filename)="data">
                        {{ data.item.filename.substring(0,50) }}
                    </template>
                    <template #cell(delete)="data">
                        <b-button variant="link" @click="deleteFileRow(data.item.id)" v-can="editionPermission">
                            <b-icon icon="x-octagon-fill" variant="danger"></b-icon>
                        </b-button>
                    </template>
                    <template #cell(download)="data">
                        <b-button variant="link" @click="downloadBrokerFile(data.item.id)" v-can="editionPermission">
                            <b-icon icon="cloud-download-fill" variant="success"></b-icon>
                        </b-button>
                    </template>
                    <template #cell(filesize)="data">
                        {{ formatBytes(data.item.filesize) }}
                    </template>
                </b-table>
                <b-pagination v-model="fileCurrentPage" :total-rows="fileRows" :per-page="filePerPage" aria-controls="brokers-file" @input="loadGridFiles()"></b-pagination>
            </b-card>

            <b-card bg-variant="light" sub-title="Contacts" class="mt-3" v-if="!loading && this.$route.params.id">
                <b-container fluid class="px-0">
                    <b-row align-v="center" class="mb-2 mt-3 px-0">
                        <b-col>
                            <b-button pill variant="primary" size="sm" v-b-modal.modal-contact v-can="editionPermission">
                                <b-icon icon="plus-circle"></b-icon>
                                Add
                            </b-button>
                        </b-col>
                        <b-col>
                            <b-input-group size="sm" class="mb-2">
                            <b-input-group-prepend is-text>
                                <b-icon icon="search"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input v-model="filter" type="search" placeholder="Search by name" @input="loadGridContacts()"></b-form-input>
                        </b-input-group>
                        </b-col>
                    </b-row>
                </b-container>
                <b-table striped hover :items="brokerContacts" :fields="fields" :filter="filter" :per-page="perPage" sort-icon-left :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" @sort-changed="loadGridContacts()">
                    <template #cell(edit)="data">
                        <b-button variant="link" :to="data.item.links.self_pattern" ref="broker" v-can="editionPermission">
                            <b-icon icon="pencil-square" variant="info"></b-icon>
                        </b-button>
                    </template>
                    <template #cell(delete)="data">
                        <b-button variant="link" @click="deleteRow(data.item.links.self_pattern)" v-can="editionPermission">
                            <b-icon icon="x-octagon-fill" variant="danger"></b-icon>
                        </b-button>
                    </template>
                </b-table>
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="brokers-table" @input="loadGridContacts()"></b-pagination>
            </b-card>
            <b-card bg-variant="light" sub-title="History" class="mt-3" v-if="!loading && this.$route.params.id">
                <History :dataType="endpoint" :addBy="form.addBy" :addDate="form.addDate" :lastModBy="form.modBy" :lastMod="form.modDate" />
            </b-card>
              <b-container class="mt-4" fluid>
                  <b-row>
                      <b-col>
                          <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block" @hidden="onHidden">
                              <b-button class="mr-2" type="button" variant="primary" :disabled="loading" ref="button" @click.prevent="onSubmit()" v-can="editionPermission">
                                  <b-icon icon="file-earmark"></b-icon>
                                  Save
                              </b-button>
                          </b-overlay>
                          <b-button variant="outline-primary" to="/brokers/" ref="broker">
                              <b-icon icon="arrow-return-left"></b-icon>
                              Go Back
                          </b-button>
                      </b-col>
                      <b-col class="text-right">
                          <b-button class="mr-2" type="reset" variant="outline-danger" @click.prevent="onReset()">
                              <b-icon icon="arrow-counterclockwise"></b-icon>
                              Reset
                          </b-button>
                          <b-button type="button" variant="danger" @click.prevent="deleteRow()" v-can="'Delete_Brokers'">
                              <b-icon icon="trash"></b-icon>
                              Delete
                          </b-button>
                      </b-col>
                  </b-row>
              </b-container>
          </b-form>
      </b-overlay>
      <b-modal id="modal-contact" size="xl" title="Contacts" ok-only>
        <ContactGrid @returnContact="postBrokerContact" :homeButton="false" :addButton="true" :deleteButton="false" :editButton="false" :returnButton="true"></ContactGrid>
      </b-modal>
    </div>
  </template>
  
  <script>
  import { required, minLength, email } from 'vuelidate/lib/validators'
  import axios from 'axios'
  import History from '../components/History.vue'
  import Feedback from '../mixins/Feedback.js'
  import ContactGrid from '../components/ContactGrid.vue'
  import Basic from '../mixins/Basic.js'
  export default {
      components:{
        History,
        ContactGrid
      },
      mixins: [Basic, Feedback],
      validations() {
          return {
              form: {
                  nickname: {
                    required,
                    minLength: minLength(1)
                  },
                  name: {
                      required,
                      minLength: minLength(1)
                  },
                  email_address: {
                      email
                  }

              }
          }
      },
      data() {
          return {
              loadingData: false,
              brokerContacts: null,
              brokerFiles: null,
              currentPage: 1,
              rows: 1,
              perPage: 5,
              fileCurrentPage: 1,
              fileRows: 1,
              filePerPage: 5,
              filter: '',
              log: '',
              uploadFileSelected: null,
              sortBy: 'first_name',
              sortDesc: false,
              fileSortBy: 'filename',
              fileSortDesc: false,
              editionPermission: 'Create_Brokers',
              fileFilter: '',
              form: {
                  nickname: '',
                  name: '',
                  email_address: '',
                  phone_number: '',
                  fax_number: '',
                  address: '',
                  city: '',
                  state: '',
                  postal_code: '',
                  addBy: '',
                  addDate: '',
                  modBy: '',
                  modDate: '',
                  cigoid: ''
              },
              fileFields: [
                {
                    key: 'filename',
                    sortable: true,
                    label: 'File name'
                },
                {
                    key: 'filesize',
                    sortable: true,
                    label: 'Size'
                },
                {
                    key: 'addBy',
                    sortable: true,
                    label: 'Uploaded by'
                },
                {
                    key: 'addDate',
                    sortable: true,
                    label: 'Uploaded on'
                },
                {
                    key: 'download',
                    sortable: false,
                    label: 'Download'
                },
                {
                    key: 'delete',
                    sortable: false,
                    label: 'Delete'
                }

            ],
              fields: [
                {
                    key: 'first_name',
                    sortable: true,
                    label: 'First Name'
                },
                {
                    key: 'last_name',
                    sortable: true,
                    label: 'Last Name'
                },
                {
                    key: 'email_address',
                    sortable: true,
                    label: 'E-mail'
                },
                {
                    key: 'phone_number',
                    sortable: true,
                    label: 'Phone Number'
                },
                {
                    key: 'cigoid',
                    sortable: true,
                    label: 'Cigo ID'
                },
                {
                    key: 'delete',
                    sortable: false,
                    label: 'Delete'
                }
              ],
              show: true,
              loading: false,
              endpoint: 'broker'
          }
      },
      methods: {
          async postBrokerContact(id){
            try {
                var id_contact = id.split('/')
                var obj = {
                    id_broker: this.$route.params.id,
                    id_contact: id_contact[2]
                }

                var endpoint = "broker/contact"
                await axios.post(endpoint, obj)
                this.loadGridContacts()
                this.$bvModal.hide('modal-contact')
            } catch (error) {
                this.errorAlert(error);
            }

          },
          validateState(name) {
              const { $dirty, $error } = this.$v.form[name];
              return $dirty ? !$error : null;
          },
          onHidden() {
              this.$refs.button.focus()
          },
          async onSubmit() {
              this.$v.form.$touch();
              if (this.$v.form.$anyError) {
                  return;
              }
  
              this.loading = true
              await this.processForm()
              this.loading = false
          },
  
          async processForm(){
              let data = this.form
  
              if(this.$route.params.id){
                  var putEndpoint = this.endpoint + "/" + this.$route.params.id;
                  this.putData(putEndpoint, data)
              } else {
                  this.postData(this.endpoint, data);
              }
          },
  
          async putData(endpoint, data) {
              try {
                  await axios.put(endpoint, data);
                  this.succesAlert()
                  this.getData();
  
              } catch (error) {
                  this.errorAlert(error);
              }
          },
  
          async postData(endpoint, data) {
              try {
                  let broker = await axios.post(endpoint, data);
                  let id = broker.data[1].links.self_pattern.split("/").pop();
                  this.succesAlert()
                  this.$router.push("/" + this.endpoint + '/' + id);
                  this.getData();
  
              } catch (error) {
                  console.log(error)
                  this.errorAlert(error);
              }
          },

          async uploadBrokerFile(){
            this.output = 'Uploading file...'
            try {
                let formData = new FormData();
                formData.append('file', this.uploadFileSelected);
                var processFileService = '/broker/'+this.$route.params.id+"/import"
                let response = await axios.post(processFileService,
                    formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                    }
                );

                //set upload success response
                this.output = response.data.success;
                this.loadGridFiles()
                this.$bvModal.hide('upload')

            } catch (error) {
                console.log(error)
                this.log = error.response.data.error
            }
        },

        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.uploadBrokerFile()
        },

        formatBytes(bytes, decimals = 2) {
            if (!+bytes) return '0 Bytes'

            const k = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

            const i = Math.floor(Math.log(bytes) / Math.log(k))

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
        },
  
        async getData(){
            if(this.$route.params.id){
                this.editionPermission = 'Update_Brokers';
                try {
                    this.loadingData = true
                    var getEndpoint = this.endpoint + "/" + this.$route.params.id;
                    let broker = await axios.get(getEndpoint)
                    this.form.nickname = broker.data[0].data.nickname
                    this.form.name = broker.data[0].data.name
                    this.form.email_address = broker.data[0].data.email_address
                    this.form.phone_number = broker.data[0].data.phone_number
                    this.form.fax_number = broker.data[0].data.fax_number
                    this.form.address = broker.data[0].data.address
                    this.form.city = broker.data[0].data.city
                    this.form.state = broker.data[0].data.state
                    this.form.postal_code = broker.data[0].data.postal_code
                    this.form.addBy = broker.data[0].data.addBy
                    this.form.addDate = broker.data[0].data.addDate
                    this.form.modBy = broker.data[0].data.modBy
                    this.form.modDate = broker.data[0].data.modDate
                    this.form.cigoid = broker.data[0].data.cigoid
                    this.loadingData = false
  
                } catch(error){
                    console.log(error)
                    this.loadingData = false
                    this.errorAlert(error);
                }

                await this.loadGridContacts()
                await this.loadGridFiles()
            }
        },

        async downloadBrokerFile(id){
            this.downloadItem(this.endpoint + '/file/' + id)
        },

        async loadGridContacts(){
            try {
                if(this.sortBy==''||this.sortBy==null) return
                var getEndpoint = this.endpoint + "/" + this.$route.params.id;
                var sortDirection = 'asc';
                if(this.sortDesc) sortDirection = 'desc';
                var contactEndpoint = getEndpoint+'/contact'+'?name='+this.filter+'&orderBy='+this.sortBy+','+sortDirection+'&pageNumber='+this.currentPage+'&size='+this.perPage
                let response = await axios.get(contactEndpoint);
                this.brokerContacts = response.data.data
                this.rows = response.data.page.totalElements
            } catch(error){
                console.log(error)
                this.brokerContacts = null
                this.rows = 0
            }
        },

        async loadGridFiles(){
            try {
                if(this.fileSortBy==''||this.fileSortBy==null) return
                var getEndpoint = this.endpoint + "/" + this.$route.params.id;
                var sortDirection = 'asc';
                if(this.fileSortDesc) sortDirection = 'desc';
                var contactEndpoint = getEndpoint+'/file'+'?name='+this.fileFilter+'&orderBy='+this.fileSortBy+','+sortDirection+'&pageNumber='+this.fileCurrentPage+'&size='+this.filePerPage
                let response = await axios.get(contactEndpoint);
                this.brokerFiles = response.data.data
                this.fileRows = response.data.page.totalElements
            } catch(error){
                console.log(error)
                this.brokerFiles = null
                this.fileRows = 0
            }
        },
  
        deleteRow(id_brokerContact){
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(async value => {
                    if(value){
                        try {
                            var response = await axios.delete(id_brokerContact)
                            if(response.status==204){
                                this.loadGridContacts()
                            }
                        } catch (error) {
                            this.errorAlert(error);
                            console.log(error)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        deleteFileRow(id_file){
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(async value => {
                    if(value){
                        try {
                            var response = await axios.delete("/broker/file/"+id_file)
                            if(response.status==204){
                                await this.loadGridFiles()
                            }
                        } catch (error) {
                            this.errorAlert(error);
                            console.log(error)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
  
        onReset() {
            if(typeof this.$route.params.id === 'undefined'){
                this.form.nickname = ''    
                this.form.name = ''
                this.form.email_address = ''
                this.form.phone_number = ''
                this.form.fax_number = ''
                this.form.address = ''
                this.form.city = ''
                this.form.state = ''
                this.form.postal_code = ''
                this.form.addBy = ''
                this.form.addDate = ''
                this.form.modBy = ''
                this.form.modDate = '',
                this.form.cigoid = ''
            }
            
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    created(){
        this.getData()
    }
}
</script>
<style scoped>
.red{
    font-size: small;
    color: red;
}

.smaller {
    font-size: small;
}
</style>