<template>

    <div role="tablist" class="mb-4">
        <h6><b-icon icon="lock-fill"></b-icon>{{permissionGroupName}}</h6>
        <b-form-checkbox
            v-for="option in permissions"
            v-model="checked"
            :key="option.id"
            :value="option.id"
            :name="option.id_permissionGroup"
            switch
            inline
            @change="save"
        >
            {{ option.name }}
        </b-form-checkbox>
    </div>
</template>

<script>
import Feedback from '../mixins/Feedback.js'
import axios from 'axios'
export default {
    name: 'PermissionGroupByUser',
    mixins: [Feedback],
    data() {
        return {
            permissionGroupName: null,
            permissions: null,
            checked: []
        };
    },
    props: {
        userId: {
            type: String,
            required: true
        },
        permissionGroupId: {
            type: String,
            required: true
        },
    },
    methods: {
        async getData(){
            let permissionGroup = await axios.get("permissiongroup/"+this.permissionGroupId)
            this.permissionGroupName = permissionGroup.data[0].data.name

            let permissionsOfGroup = await axios.get("permission/findByGroup/"+this.permissionGroupId)
            this.permissions = permissionsOfGroup.data.data
            
            let userPermission = await axios.get("user/"+ this.userId +"/permission/"+this.permissionGroupId)
            userPermission.data.data.forEach(element => {
                this.checked.push(element.id_permission)
            });
        },
        async save(){
            
            try {
                var deleteurl = 'user/'+this.userId+'/permissiongroup/'+this.permissionGroupId
                await axios.delete(deleteurl)
                for (let index = 0; index < this.checked.length; index++) {
                    const element = this.checked[index];
                    var obj = {
                        id_user: this.userId,
                        id_permission: element
                    }

                    var endpoint = "user/permission"
                    await axios.post(endpoint, obj)
                    this.succesAlert()
                }

            } catch (error) {
                console.log(error)
                this.errorAlert(error);
            }
        }
    },
    created(){
        this.getData()
    }
}
</script>