<template>
    <div v-can="'Read_Payroll'" class="m-5">
        <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                <span v-html="feedbackMessage"></span>
            </b-alert>
        </div>
        <b-container fluid>
            <b-row align-h="start">
                <b-col class="pl-0 pr-2" md="auto">
                    <h3>Payroll</h3>
                </b-col>
                <b-col cols="7" class="p-0">
                    <b-button pill variant="outline-primary" size="sm" to="/payrolls/" ref="payroll">
                        <b-icon icon="arrow-return-left"></b-icon>
                        Go Back
                    </b-button>
                    <b-button class="ml-2" pill variant="outline-primary" size="sm" to="/home/" ref="home">
                        <b-icon icon="house"></b-icon>
                        Home
                    </b-button>
                </b-col>
            </b-row>
        </b-container>
        <b-overlay :show="loading" rounded="sm">
            <template #overlay>
                <div class="text-center">
                    <b-spinner variant="primary" label="Text Centered"></b-spinner>
                    <div class="mb-3">{{output}}</div>
                </div>
            </template>
            <b-form v-if="show">
                <b-card bg-variant="light" sub-title="Period" class="mt-3">
                    <b-form-group class="m-3" v-can:input="editionPermission">
                        <b-form-datepicker placeholder="Start date" v-model="form.start_date" class="m-2" :state="validateState('start_date')" aria-describedby="date-range-live-feedback"></b-form-datepicker>
                        <b-form-datepicker placeholder="End date" v-model="form.end_date" class="m-2" :state="validateState('end_date')" aria-describedby="date-range-live-feedback"></b-form-datepicker>
                        <b-form-invalid-feedback id="date-range-live-feedback">These are required fields and the start date must be before the end date.</b-form-invalid-feedback>
                    </b-form-group>
                </b-card>
                <b-card bg-variant="light" sub-title="Source" class="mt-3">
                    <b-form-select v-model="form.id_source" :state="validateState('id_source')" :options="sources" value-field="id" text-field="name" v-can:input="editionPermission" aria-describedby="id_source-live-feedback">
                        <template #first>
                            <b-form-select-option :value=0>Select a source</b-form-select-option>
                        </template>
                    </b-form-select>
                    <b-form-invalid-feedback id="id_client-live-feedback">Select a source</b-form-invalid-feedback>
                </b-card>

                <b-card bg-variant="light" sub-title="Notes" class="mt-3">
                    <b-form-group class="m-3" id="input-group-2" v-can:textarea="editionPermission">
                        <b-textarea id="input-notes" v-model="form.notes" placeholder="Enter notes" rows="10"></b-textarea>
                    </b-form-group>
                </b-card>

                <b-card bg-variant="light" sub-title="Payroll Results" class="mt-3" v-show="hasFileProcessed">
                    <b-form-group class="m-3" label="Total registers generated:">
                        {{form.total_reg_generated}}
                    </b-form-group>
                    <b-form-group class="m-3" label="Number of brokers:">
                        {{form.number_of_brokers}}
                    </b-form-group>
                    <b-form-group class="m-3" label="Total (CAD):">
                        {{form.total}}
                    </b-form-group>
                    <b-form-group class="m-3" label="Download Payroll file:">
                        <b-button @click="downloadItem(form.downloadService)" variant="link">
                            <b-img src="../assets/excel.png" alt="Transport Regent"></b-img>
                        </b-button>
                    </b-form-group>
                </b-card>

                <b-card bg-variant="light" sub-title="History" class="mt-3" v-if="!loading && this.$route.params.id">
                    <History :dataType="endpoint" :addBy="form.addBy" :addDate="form.addDate" :lastModBy="form.modBy" :lastMod="form.modDate" />
                </b-card>

                <b-container class="mt-4" fluid>
                    <b-row>
                        <b-col>
                            <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                                <b-button class="mr-2" type="button" variant="primary" :disabled="loading" ref="button" @click.prevent="onSubmit(false)">
                                    <b-icon icon="file-earmark"></b-icon>
                                    Only Save
                                </b-button>
                                <b-button class="mr-2" type="button" variant="success" :disabled="loading" ref="button" @click.prevent="onSubmit(true)">
                                    <b-icon icon="file-earmark"></b-icon>
                                    Save and Process
                                </b-button>
                            </b-overlay>
                            <b-button variant="outline-primary" to="/payrolls/" ref="payroll">
                                <b-icon icon="arrow-return-left"></b-icon>
                                Go Back
                            </b-button>
                        </b-col>
                        <b-col class="text-right">
                            <b-button class="mr-2" type="reset" variant="outline-danger" @click.prevent="onReset()">
                                <b-icon icon="arrow-counterclockwise"></b-icon>
                                Reset
                            </b-button>
                            <b-button type="button" variant="danger" @click.prevent="deleteRow()" v-can="'Delete_Billing'">
                                <b-icon icon="trash"></b-icon>
                                Delete
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </b-form>
        </b-overlay>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from 'axios'
import Feedback from '../mixins/Feedback.js'
import Basic from '../mixins/Basic.js'
import History from '../components/History.vue'
import excel from "@/assets/excel.png";
export default {
    mixins: [Feedback, Basic],
    components: {
        History
    },
    validations() {
        return {
            form: {
                start_date: {
                    required,
                    maxValue(val, {end_date}){
                        return new Date(end_date) >= new Date(val);
                    }
                },
                end_date: {
                    required,
                    minValue(val, { start_date }) {
                        return new Date(val) >= new Date(start_date);
                    }
                },
                id_source: {
                    required(val){
                        return val == 0 ? false : true;
                    }
                }
            }
        }
    },
    data() {
        return {
            excel,
            output: [],
            loading: false,
            message: '',
            show: true,
            endpoint: 'payroll',
            hasFileProcessed: false,
            editionPermission: 'Create_Payroll',
            sources: [],
            form:{
                id: 0,
                start_date: '',
                end_date: '',
                id_source: 0,
                total_reg_migrated: 0,
                total_reg_generated: 0,
                number_of_brokers: 0,
                total: 0,
                notes: '',
                input_file: '',
                output_file: '',
                addBy: '',
                addDate: '',
                modBy: '',
                modDate: '',
                downloadService: ''
            }
        }
    },
    methods: {
        async getSources(){
            this.sources = [{"id":"1", 'name':'Progression'},{'id':'2', 'name':'Cigo'}]
        },

        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },

        onHidden() {
            this.$refs.button.focus()
        },

        async onSubmit(process) {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            this.loading = true
            this.output = 'Saving...'
            await this.processForm(process)
            this.loading = false
        },

        async processForm(process){
            let data = this.form

            if(this.$route.params.id){
                var putEndpoint = this.endpoint + "/" + this.$route.params.id;
                await this.putData(putEndpoint, data, process)
            } else {
                await this.postData(this.endpoint, data, process);
            }

            if(process){
                await this.fetchServerFile();

                this.output = "Processing PAYROLL file";
                var url = "payroll/export/" + this.$route.params.id + '/excel';
                await this.downloadItem(url);
                await this.getData();
            }
        },

        async fetchServerFile(){
            var systemName = "";
            if(this.form.id_source=='1'){
                systemName = "Progression"
            } else {
                systemName = "Cigo"
            }
            this.output = 'Fetching data on ' + systemName + '...';

            try {
                this.loading = true;
                var processFileService = '/payroll/fetch/'+this.$route.params.id
                let response = await axios.get(processFileService);
                this.output = response.data.success;

            } catch (error) {
                this.errorAlert(error.response.data.error);
                this.loading = false;
            }
        },

        async putData(endpoint, data, process) {
            try {
                await axios.put(endpoint, data);
                if(!process) this.succesAlert()
                await this.getData();

            } catch (error) {
                this.errorAlert(error);
            }
        },

        async postData(endpoint, data, process) {
            try {
                let payroll = await axios.post(endpoint, data);
                let id = payroll.data[1].links.self_pattern.split("/").pop();
                if(!process) this.succesAlert()
                this.$router.push("/" + this.endpoint + '/' + id);
                await this.getData();

            } catch (error) {
                console.log(error)
                this.errorAlert(error);
            }
        },

        async getData(){
            await this.getSources()
            if(this.$route.params.id){
                try {
                    this.loading = true
                    var getEndpoint = this.endpoint + "/" + this.$route.params.id
                    let payroll = await axios.get(getEndpoint)
                    this.form.id = this.$route.params.id
                    this.form.start_date = payroll.data[0].data.start_date
                    this.form.end_date = payroll.data[0].data.end_date
                    this.form.id_source = payroll.data[0].data.id_source
                    this.form.total_reg_migrated = payroll.data[0].data.total_reg_migrated.toLocaleString('en-CA', {minimumFractionDigits: 0, style: 'decimal', currency: 'CAD'})
                    this.form.total_reg_generated = payroll.data[0].data.total_reg_generated.toLocaleString('en-CA', {minimumFractionDigits: 0, style: 'decimal', currency: 'CAD'})
                    this.form.number_of_brokers = payroll.data[0].data.number_of_brokers
                    this.form.total = payroll.data[0].data.total.toLocaleString('en-CA', {minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'decimal', currency: 'CAD'})
                    this.form.notes = payroll.data[0].data.notes
                    this.form.input_file = payroll.data[0].data.input_file
                    this.form.output_file = payroll.data[0].data.output_file
                    this.form.addBy = payroll.data[0].data.addBy
                    this.form.addDate = payroll.data[0].data.addDate
                    this.form.modBy = payroll.data[0].data.modBy
                    this.form.modDate = payroll.data[0].data.modDate
                    this.form.downloadService = '/payroll/download/'+this.form.id+'/excel'
                    this.loading = false

                    this.editionPermission = 'Update_Payroll';

                    if(this.form.output_file!=""&&this.form.output_file!=null){
                        this.hasFileProcessed = true;
                    } else {
                        this.hasFileProcessed = false;
                    }

                } catch(error){
                    console.log(error)
                    this.loading = false
                    this.errorAlert(error);
                }
            }
        },

        deleteRow(){
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(async value => {
                    if(value){
                        try {
                            var id = 'payroll/'+this.$route.params.id
                            var response = await axios.delete(id)
                            if(response.status==204){
                                this.$router.push('/payrolls/');
                            }
                        } catch (error) {
                            this.errorAlert(error);
                            console.log(error)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        onReset() {
            if(typeof this.$route.params.id === 'undefined'){
                this.form.name = ''
                this.form.id = 0
                this.form.start_date = ''
                this.form.end_date = ''
                this.form.total_reg_migrated = 0
                this.form.total_reg_generated = 0
                this.form.number_of_brokers = 0
                this.form.total = 0
                this.form.notes = ''
                this.form.input_file = ''
                this.form.output_file = ''
                this.form.addBy = ''
                this.form.addDate = ''
                this.form.modBy = ''
                this.form.modDate = ''
            }
            
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    created(){
        this.getData()
    }
}
</script>