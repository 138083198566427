<template>        
    <b-container class="container">
        <div class="login-form">
            <b-row align="center" align-v="center">
                <b-col>
                    <b-img src="../assets/logo.png" alt="Transport Regent"></b-img>
                    <h3 class="m-4">Login</h3>
                    <b-form class="mb-5" @keyup.enter="login()">
                        <b-form-group class="m-3" id="input-group-1" label-for="username">
                            <b-form-input id="username" v-model="$v.form.username.$model" type="text" placeholder="Enter username" :state="validateState('username')" aria-describedby="input-1-live-feedback"></b-form-input>
                            <b-form-invalid-feedback id="input-1-live-feedback">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group class="m-3" id="input-group-2" label-for="password">
                            <b-form-input id="password" v-model="$v.form.password.$model" type="password" placeholder="Enter password" :state="validateState('password')" aria-describedby="input-2-live-feedback"></b-form-input>
                            <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group class="m-3" align="right">
                            <b-button type="button" variant="primary" @click.prevent="login()">Login</b-button>
                        </b-form-group>
                        
                        <b-alert
                        :show="$store.getters.isCredentialIncorrect"
                        variant="danger"
                        @dismissed="!$store.getters.isCredentialIncorrect"
                        >
                        Username and password are incorrect.
                        </b-alert>
                    </b-form>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import logo from "@/assets/logo.png";
import axios from 'axios';
import {
    mapActions
} from "vuex";

export default {
    name: 'App',
    components: {
        
    },
    validations: {
        form: {
            username: {
                required
            },
            password: {
                required
            }
        }
    },
    data() {
        return {
            logo,
            form: {
                username: null,
                password: null,
            },
            dismissSecs: 5,
            dismissCountDown: 0
        }
    },
    methods: {
        showWrongCredentialsAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        ...mapActions(["logIn"]),

        async login() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            if (this.username != "" && this.password != "") {

                let user = {
                    username: this.form.username,
                    password: this.form.password
                }
                try {
                    axios.defaults.withCredentials = true;
                    await this.logIn(user);
                } catch (error) {
                    console.log(error);
                    axios.defaults.withCredentials = false;
                }

            }
        }
    }
}
</script>

<style>
.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 5%;
}

.login-form {
    padding: 5%;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

html,
body {
  height: 100%
}
</style>
