<template>
  <div id="app">
    <div id="content-wrap">
      <NavBar />
      <router-view class="views-padding" />
    </div>
    <div id="footer">
      <MyFooter />
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import MyFooter from '@/components/MyFooter.vue'

export default {
  metaInfo: {
    title: 'Transport Regent',
  },
  components: {
    NavBar,
    MyFooter
  },
  data() {
    return {

    }
  },
  mounted() {

  },

  methods: {

  }
}
</script>

<style>
#app {
  position: relative;
  min-height: 100vh;
}
#content-wrap {
  padding-bottom: 1.5rem;
}
#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.5rem;
}
</style>
