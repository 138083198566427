<template>
    <div v-can="'Read_Contacts'" class="m-5">
      <div class="fixed-top mt-3 d-flex justify-content-center">
          <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
              {{ feedbackMessage }}
          </b-alert>
      </div>
      <b-container fluid>
          <b-row align-h="start">
              <b-col class="pl-0 pr-2" md="auto">
                  <h3>Contacts</h3>
              </b-col>
              <b-col cols="7" class="p-0">
                  <b-button pill variant="outline-primary" size="sm" to="/contacts/" ref="contact">
                      <b-icon icon="arrow-return-left"></b-icon>
                      Go Back
                  </b-button>
                  <b-button class="ml-2" pill variant="outline-primary" size="sm" to="/home/" ref="home">
                      <b-icon icon="house"></b-icon>
                      Home
                  </b-button>
              </b-col>
          </b-row>
      </b-container>
      <b-overlay :show="loadingData" rounded="sm">
          <b-form v-if="show">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <b-form-group id="input-group-first_name" label="First Name:" label-for="input-first_name" description="Minimal 1 alphanumeric characters.">
                            <b-form-input id="input-first_name" v-model="form.first_name" type="text" placeholder="Enter first name" :state="validateState('first_name')" aria-describedby="first_name-live-feedback" v-can:input="editionPermission"></b-form-input>
                            <b-form-invalid-feedback id="first_name-live-feedback">This is a required field (minimal 1 character).</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="input-group-last_name" label="Last Name:" label-for="input-last_name" description="Minimal 1 alphanumeric characters.">
                            <b-form-input id="input-last_name" v-model="form.last_name" type="text" placeholder="Enter last name" :state="validateState('last_name')" aria-describedby="last_name-live-feedback" v-can:input="editionPermission"></b-form-input>
                            <b-form-invalid-feedback id="last_name-live-feedback">This is a required field (minimal 1 character).</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group id="input-group-position" label="Position:" label-for="input-position">
                            <b-form-input id="input-position" v-model="form.position" type="text" placeholder="Enter position" v-can:input="editionPermission"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="input-group-email_address" label="E-mail address:" label-for="input-email_address">
                            <b-form-input id="input-email_address" v-model="form.email_address" type="text" placeholder="Enter e-mail address" :state="validateState('email_address')" aria-describedby="email_address-live-feedback" v-can:input="editionPermission"></b-form-input>
                            <b-form-invalid-feedback id="email_address-live-feedback">Use a valid e-mail address.</b-form-invalid-feedback>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group id="input-group-phone_number" label="Phone Number:" label-for="input-phone_number">
                            <b-form-input id="input-phone_number" v-model="form.phone_number" type="text" placeholder="Enter phone number" v-can:input="editionPermission"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="input-group-fax_number" label="Fax Number:" label-for="input-fax_number">
                            <b-form-input id="input-fax_number" v-model="form.fax_number" type="text" placeholder="Enter fax number" v-can:input="editionPermission"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group id="input-group-address" label="Address:" label-for="input-address">
                            <b-form-input id="input-address" v-model="form.address" type="text" placeholder="Enter address" v-can:input="editionPermission"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group id="input-group-city" label="City:" label-for="input-city">
                            <b-form-input id="input-city" v-model="form.city" type="text" placeholder="City" v-can:input="editionPermission"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="input-group-state" label="State:" label-for="input-state">
                            <b-form-input id="input-state" v-model="form.state" type="text" placeholder="Enter state" v-can:input="editionPermission"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group id="input-group-postal_code" label="Postal code:" label-for="input-postal_code">
                            <b-form-input id="input-postal_code" v-model="form.postal_code" type="text" placeholder="Enter postal code" v-can:input="editionPermission"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-container>
            <b-card bg-variant="light" sub-title="History" class="mt-3" v-if="!loading && this.$route.params.id">
                <History :dataType="endpoint" :addBy="form.addBy" :addDate="form.addDate" :lastModBy="form.modBy" :lastMod="form.modDate" />
            </b-card>
  
              <b-container class="mt-4" fluid>
                  <b-row>
                      <b-col>
                          <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block" @hidden="onHidden">
                              <b-button class="mr-2" type="button" variant="primary" :disabled="loading" ref="button" @click.prevent="onSubmit()" v-can="editionPermission">
                                  <b-icon icon="file-earmark"></b-icon>
                                  Save
                              </b-button>
                          </b-overlay>
                          <b-button variant="outline-primary" to="/contacts/" ref="contact">
                              <b-icon icon="arrow-return-left"></b-icon>
                              Go Back
                          </b-button>
                      </b-col>
                      <b-col class="text-right">
                          <b-button class="mr-2" type="reset" variant="outline-danger" @click.prevent="onReset()">
                              <b-icon icon="arrow-counterclockwise"></b-icon>
                              Reset
                          </b-button>
                          <b-button type="button" variant="danger" @click.prevent="deleteRow()" v-can="'Delete_Contacts'">
                              <b-icon icon="trash"></b-icon>
                              Delete
                          </b-button>
                      </b-col>
                  </b-row>
              </b-container>
          </b-form>
      </b-overlay>
    </div>
  </template>
  
  <script>
  import { required, minLength, email } from 'vuelidate/lib/validators'
  import axios from 'axios'
  import History from '../components/History.vue'
  import Feedback from '../mixins/Feedback.js'
  export default {
      components:{
        History
      },
      mixins: [Feedback],
      validations() {
          return {
              form: {
                  first_name: {
                      required,
                      minLength: minLength(1)
                  },
                  last_name: {
                      required,
                      minLength: minLength(1)
                  },
                  email_address: {
                      email
                  }

              }
          }
      },
      data() {
          return {
              loadingData: false,
              form: {
                  first_name: '',
                  last_name: '',
                  position: '',
                  email_address: '',
                  phone_number: '',
                  fax_number: '',
                  address: '',
                  city: '',
                  state: '',
                  postal_code: '',
                  addBy: '',
                  addDate: '',
                  modBy: '',
                  modDate: ''
              },
              show: true,
              loading: false,
              endpoint: 'contact',
              editionPermission: 'Create_Contacts'
          }
      },
      methods: {
          validateState(name) {
              const { $dirty, $error } = this.$v.form[name];
              return $dirty ? !$error : null;
          },
          onHidden() {
              this.$refs.button.focus()
          },
          async onSubmit() {
              this.$v.form.$touch();
              if (this.$v.form.$anyError) {
                  return;
              }
  
              this.loading = true
              await this.processForm()
              this.loading = false
          },
  
          async processForm(){
              let data = this.form
  
              if(this.$route.params.id){
                  var putEndpoint = this.endpoint + "/" + this.$route.params.id;
                  this.putData(putEndpoint, data)
              } else {
                  this.postData(this.endpoint, data);
              }
          },
  
          async putData(endpoint, data) {
              try {
                  await axios.put(endpoint, data);
                  this.succesAlert()
                  this.getData();
  
              } catch (error) {
                  this.errorAlert(error);
              }
          },
  
          async postData(endpoint, data) {
              try {
                  let contact = await axios.post(endpoint, data);
                  let id = contact.data[1].links.self_pattern.split("/").pop();
                  this.succesAlert()
                  this.$router.push("/" + this.endpoint + '/' + id);
                  this.getData();
  
              } catch (error) {
                  console.log(error)
                  this.errorAlert(error);
              }
          },
  
          async getData(){
              if(this.$route.params.id){
                this.editionPermission = 'Update_Contacts';
                try {
                    this.loadingData = true
                    var getEndpoint = this.endpoint + "/" + this.$route.params.id;
                    let contact = await axios.get(getEndpoint)
                    this.form.first_name = contact.data[0].data.first_name
                    this.form.last_name = contact.data[0].data.last_name
                    this.form.position = contact.data[0].data.position
                    this.form.email_address = contact.data[0].data.email_address
                    this.form.phone_number = contact.data[0].data.phone_number
                    this.form.fax_number = contact.data[0].data.fax_number
                    this.form.address = contact.data[0].data.address
                    this.form.city = contact.data[0].data.city
                    this.form.state = contact.data[0].data.state
                    this.form.postal_code = contact.data[0].data.postal_code
                    this.form.addBy = contact.data[0].data.addBy
                    this.form.addDate = contact.data[0].data.addDate
                    this.form.modBy = contact.data[0].data.modBy
                    this.form.modDate = contact.data[0].data.modDate
                    this.loadingData = false
  
                } catch(error){
                    console.log(error)
                    this.loadingData = false
                    this.errorAlert(error);
                }
            }
        },
  
          deleteRow(){
              this.$bvModal.msgBoxConfirm('Are you sure?')
                  .then(async value => {
                      if(value){
                          try {
                              var id = 'contact/'+this.$route.params.id
                              var response = await axios.delete(id)
                              if(response.status==204){
                                  this.$router.push('/contacts/');
                              }
                          } catch (error) {
                              this.errorAlert(error);
                              console.log(error)
                          }
                      }
                  })
                  .catch(err => {
                      console.log(err)
                  })
          },
  
          onReset() {
              if(typeof this.$route.params.id === 'undefined'){
                    this.form.first_name = ''
                    this.form.last_name = ''
                    this.form.position = ''
                    this.form.email_address = ''
                    this.form.phone_number = ''
                    this.form.fax_number = ''
                    this.form.address = ''
                    this.form.city = ''
                    this.form.state = ''
                    this.form.postal_code = ''
                    this.form.addBy = ''
                    this.form.addDate = ''
                    this.form.modBy = ''
                    this.form.modDate = ''
              }
              
              this.show = false
              this.$nextTick(() => {
                  this.show = true
              })
          }
      },
      created(){
          this.getData()
      }
  }
  </script>