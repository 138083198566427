<template>
    <div v-can="'Read_Contacts'">
        <ContactGrid :homeButton="true" :addButton="true" :deleteButton="true" :editButton="true" :returnButton="false"></ContactGrid>
    </div>
</template>

<script>
import ContactGrid from '../components/ContactGrid.vue'

export default {
    name: 'Contacts',
    data() {
        return {
        };
    },
    components: {
        ContactGrid
    },
    methods: {

    },
    created(){
        
    }
}
</script>