<template>
<b-container class="container mt-0">
    <b-row align="center" align-v="center">
        <b-col>
            <b-img class="m-5" src="../assets/logo.png" alt="Transport Regent"></b-img>
            <h1><b-icon icon="exclamation-circle-fill" variant="danger"></b-icon> {{title}}</h1>
            <p>{{text}}</p>
            <p><b-button class="m-5" @click="goHome()">Home</b-button></p>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
export default {
    name: 'Page404',
    components: {
    },

    data() {
        return {
            title: "Not found",
            text: "The content you are trying to access is not available."
        }
    },
    methods: {
        goHome(){
            //this.$store.dispatch('setAccessedUrl', "/")
            this.$router.push("/home");
        }
    }
};
</script>

<style scoped>

</style>
