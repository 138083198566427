<template>
    <div>
        <div class="m-3 secondary">{{greeting}}, {{user.first_name}}!</div>
        <Wizard />
    </div>
</template>

<script>
import Wizard from '../components/Wizard.vue';
export default {
    name: 'Home',
    data() {
        return {
            user: this.getUser(),
            greeting: this.getGreetings()
        };
    },
    components: {
        Wizard
    },
    methods: {
        getUser() {
            return this.$store.getters.getUser
        },
        getGreetings(){
            var d = new Date();
            var time = d.getHours();
            var greeting = "";

            if (time < 12) {
                greeting = "Good morning";
            }
            if (time >= 12 && time < 18) {
                greeting = "Good afternoon";
            }
            if (time >= 18) {
                greeting = "Good evening";
            }

            return greeting;
        }
    }
}
</script>