<template>
  <div v-if="$store.getters.getUser.root=='t'" class="m-5">
    <div class="fixed-top mt-3 d-flex justify-content-center">
        <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
            {{ feedbackMessage }}
        </b-alert>
    </div>
    <b-container fluid>
        <b-row align-h="start">
            <b-col class="pl-0 pr-2" md="auto">
                <h3>Users</h3>
            </b-col>
            <b-col cols="7" class="p-0">
                <b-button pill variant="outline-primary" size="sm" to="/users/" ref="user">
                    <b-icon icon="arrow-return-left"></b-icon>
                    Go Back
                </b-button>
                <b-button class="ml-2" pill variant="outline-primary" size="sm" to="/home/" ref="home">
                    <b-icon icon="house"></b-icon>
                    Home
                </b-button>
            </b-col>
        </b-row>
    </b-container>
    <b-overlay :show="loadingData" rounded="sm">
        <b-form v-if="show">
            <b-form-group id="input-group-username" label="Username:" label-for="input-username" description="Minimal 3 alphanumeric characters.">
                <b-form-input id="input-username" v-model="form.username" type="text" placeholder="Enter username" :state="validateState('username')" aria-describedby="username-live-feedback" :readonly="this.usernameReadonly"></b-form-input>
                <b-form-invalid-feedback id="username-live-feedback">This is a required field (minimal 3 characters).</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-password" label="Password:" label-for="input-password" description="Minimal 8 alphanumeric characters.">
                <b-form-input id="input-password" v-model="form.password" type="password" placeholder="Enter password" :state="validateState('password')" aria-describedby="password-live-feedback"></b-form-input>
                <b-form-invalid-feedback id="password-live-feedback">This is a required field (minimal 8 characters).</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-password2" label="Re-enter password:" label-for="input-password2" description="Minimal 8 alphanumeric characters.">
                <b-form-input id="input-password2" v-model="form.password2" type="password" placeholder="Enter password again" :state="validateState('password2')" aria-describedby="password2-live-feedback"></b-form-input>
                <b-form-invalid-feedback id="password2-live-feedback">Passwords must be identical.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-first_name" label="First Name:" label-for="input-first_name">
                <b-form-input id="input-first_name" v-model="form.first_name" type="text" placeholder="First name" :state="validateState('first_name')" aria-describedby="first_name-live-feedback"></b-form-input>
                <b-form-invalid-feedback id="first_name-live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-last_name" label="Last Name:" label-for="input-last_name">
                <b-form-input id="input-last_name" v-model="form.last_name" type="text" placeholder="Last name"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-email" label="E-mail:" label-for="input-email">
                <b-form-input id="input-email" v-model="form.email" type="email" placeholder="E-mail" :state="validateState('email')" aria-describedby="emaiil-live-feedback"></b-form-input>
                <b-form-invalid-feedback id="email-live-feedback">Invalid e-mail.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-checkbox id="checkbox-root" v-model="form.root" name="checkbox-root" value="true" unchecked-value="false">
                Root User
            </b-form-checkbox>

            <b-container class="mt-4" fluid>
                <b-row>
                    <b-col>
                        <b-button class="mr-2" type="button" variant="success" ref="button" :to="form.link+'/permission'">
                            <b-icon icon="key-fill"></b-icon>
                            Permissions
                        </b-button>
                        <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block" @hidden="onHidden">
                            <b-button class="mr-2" type="button" variant="primary" :disabled="loading" ref="button" @click.prevent="onSubmit()">
                                <b-icon icon="file-earmark"></b-icon>
                                Save
                            </b-button>
                        </b-overlay>
                        <b-button variant="outline-primary" to="/users/" ref="user">
                            <b-icon icon="arrow-return-left"></b-icon>
                            Go Back
                        </b-button>
                    </b-col>
                    <b-col class="text-right">
                        <b-button class="mr-2" type="reset" variant="outline-danger" @click.prevent="onReset()">
                            <b-icon icon="arrow-counterclockwise"></b-icon>
                            Reset
                        </b-button>
                        <b-button type="button" variant="danger" @click.prevent="deleteRow()">
                            <b-icon icon="trash"></b-icon>
                            Delete
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-form>
    </b-overlay>
  </div>
</template>

<script>
import { required, requiredIf, minLength, email, sameAs } from 'vuelidate/lib/validators'
import axios from 'axios'
import Feedback from '../mixins/Feedback.js'
export default {
    mixins: [Feedback],
    validations() {
        return {
            form: {
                username: {
                    required,
                    minLength: minLength(3)
                },
                password: {
                    required: requiredIf(function(){
                        return typeof this.$route.params.id === 'undefined'
                    }),
                    minLength: minLength(8)
                },
                password2: {
                    required: requiredIf(function(){
                        return typeof this.$route.params.id === 'undefined'
                    }),
                    minLength: minLength(8),
                    sameAsPassword: sameAs('password')
                },
                first_name: {
                    required
                },
                email: {
                    required,
                    email
                }
            }
        }
    },
    data() {
        return {
            form: {
                username: '',
                password: '',
                password2: '',
                first_name: '',
                last_name: '',
                email: '',
                root: 'false',
                link: ''
            },
            usernameReadonly: false,
            show: true,
            loading: false,
            endpoint: 'user'
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        onHidden() {
            this.$refs.button.focus()
        },
        async onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            this.loading = true
            await this.processForm()
            this.loading = false
        },

        async processForm(){
            let data = this.form

            if(this.$route.params.id){
                var putEndpoint = this.endpoint + "/" + this.$route.params.id;
                this.putData(putEndpoint, data)
            } else {
                this.postData(this.endpoint, data);
            }
        },

        async putData(endpoint, data) {
            try {
                await axios.put(endpoint, data);
                this.succesAlert()
                this.getData();

            } catch (error) {
                this.errorAlert(error);
            }
        },

        async postData(endpoint, data) {
            try {
                let user = await axios.post(endpoint, data);
                let id = user.data[1].links.self_pattern.split("/").pop();
                this.succesAlert()
                this.$router.push("/" + this.endpoint + '/' + id);
                this.getData();

            } catch (error) {
                console.log(error)
                this.errorAlert(error);
            }
        },

        async getData(){
            if(this.$route.params.id){
                try {
                    this.loadingData = true
                    var getEndpoint = this.endpoint + "/" + this.$route.params.id;
                    let user = await axios.get(getEndpoint)
                    this.form.username = user.data[0].data.username
                    this.form.first_name = user.data[0].data.first_name
                    this.form.last_name = user.data[0].data.last_name
                    this.form.email = user.data[0].data.email
                    this.form.link = user.data[1].links.self_pattern
                    if(user.data[0].data.root=='t'){
                        this.form.root = 'true'
                    } else {
                        this.form.root = 'false'
                    }

                    this.loadingData = false

                } catch(error){
                    console.log(error)
                    this.loadingData = false
                    this.errorAlert(error);
                }
            }
        },

        enableDisableForm(){
            if(this.$route.params.id){
                this.usernameReadonly = true
            }
        },

        deleteRow(){
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(async value => {
                    if(value){
                        try {
                            var id = 'user/'+this.$route.params.id
                            var response = await axios.delete(id)
                            if(response.status==204){
                                this.$router.push('/users/');
                            }
                        } catch (error) {
                            this.errorAlert(error);
                            console.log(error)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        onReset() {
            if(typeof this.$route.params.id === 'undefined'){
                this.form.username = ''
            }
            
            this.form.password = ''
            this.form.password2 = ''
            this.form.first_name = ''
            this.form.last_name = ''
            this.form.email = ''
            this.form.root = false
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    created(){
        this.getData()
        this.enableDisableForm()
    }
}
</script>