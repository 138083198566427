<template>

    <div class="text-center">
      <span class="text-muted">Transport Regent &copy; | {{year}}</span>
    </div>

</template>

<script>
export default {
    name: 'MyFooter',
    data() {
        return {
            year: this.setYear()
        };
    },
    methods: {
      setYear(){
        var date = new Date();
        return date.getFullYear();
      }
    }
}
</script>