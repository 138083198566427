<template>
  <div v-can="'Read_PayrollPostalPrices'" class="m-5">
    <div class="fixed-top mt-3 d-flex justify-content-center">
        <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
            {{ feedbackMessage }}
        </b-alert>
    </div>
    <b-container fluid>
        <b-row align-h="start">
            <b-col class="pl-0 pr-2" md="auto">
                <h3>Payroll Price</h3>
            </b-col>
            <b-col cols="7" class="p-0">
                <b-button pill variant="outline-primary" size="sm" to="/payroll/prices/" ref="payrollPrices">
                    <b-icon icon="arrow-return-left"></b-icon>
                    Go Back
                </b-button>
                <b-button class="ml-2" pill variant="outline-primary" size="sm" to="/home/" ref="home">
                    <b-icon icon="house"></b-icon>
                    Home
                </b-button>
            </b-col>
        </b-row>
    </b-container>
    <b-overlay :show="loadingData" rounded="sm">
        <b-form v-if="show">
            <b-form-group id="input-group-postal-code" label="Postal code:" label-for="input-postal-code" description="Minimal 1 alphanumeric character." v-can:number="editionPermission">
                <b-form-input id="input-postal-code" v-model="form.code" type="text" placeholder="Enter postal code" :state="validateState('code')" aria-describedby="postal-code-live-feedback" :readonly="this.postalCodeReadonly"></b-form-input>
                <b-form-invalid-feedback id="postal-code-live-feedback">This is a required field (minimal 1 character).</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group id="input-group-price" label="Price (CAD):" label-for="input-price" description="Price is mandatory." v-can:number="editionPermission">
                <b-form-input id="input-price" v-model="form.price" type="number" placeholder="Enter price" :state="validateState('price')" aria-describedby="price-live-feedback"></b-form-input>
                <b-form-invalid-feedback id="price-live-feedback">This is a required field.</b-form-invalid-feedback>
            </b-form-group>

            <b-card bg-variant="light" sub-title="History" class="mt-3" v-if="!loading && this.$route.params.id">
                <History :dataType="endpoint" :addBy="form.addBy" :addDate="form.addDate" :lastModBy="form.modBy" :lastMod="form.modDate" />
            </b-card>

            <b-container class="mt-4" fluid>
                <b-row>
                    <b-col>
                        <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block" @hidden="onHidden">
                            <b-button class="mr-2" type="button" variant="primary" :disabled="loading" ref="button" @click.prevent="onSubmit()" v-can="editionPermission">
                                <b-icon icon="file-earmark"></b-icon>
                                Save
                            </b-button>
                        </b-overlay>
                        <b-button variant="outline-primary" to="/payroll/prices/" ref="payrollPrice">
                            <b-icon icon="arrow-return-left"></b-icon>
                            Go Back
                        </b-button>
                    </b-col>
                    <b-col class="text-right">
                        <b-button class="mr-2" type="reset" variant="outline-danger" @click.prevent="onReset()">
                            <b-icon icon="arrow-counterclockwise"></b-icon>
                            Reset
                        </b-button>
                        <b-button type="button" variant="danger" @click.prevent="deleteRow()" v-can="'Delete_PayrollPostalPrices'">
                            <b-icon icon="trash"></b-icon>
                            Delete
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-form>
    </b-overlay>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import axios from 'axios'
import Feedback from '../mixins/Feedback.js'
import History from '../components/History.vue'
export default {
    mixins: [Feedback],
    components: {
        History
    },
    validations() {
        return {
            form: {
                code: {
                    required,
                    minLength: minLength(1)
                },
                price: {
                    required
                }
            }
        }
    },
    data() {
        return {
            form: {
                code: '',
                price: 0,
                addBy: '',
                addDate: '',
                modBy: '',
                modDate: ''
            },
            postalCodeReadonly: false,
            show: true,
            loading: false,
            endpoint: 'payroll/price',
            loadingData: false,
            editionPermission: 'Create_PayrollPostalPrices'
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        onHidden() {
            this.$refs.button.focus()
        },
        async onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            this.loading = true
            await this.processForm()
            this.loading = false
        },

        async processForm(){
            let data = this.form

            if(this.$route.params.id){
                var putEndpoint = this.endpoint + "/" + this.$route.params.id;
                this.putData(putEndpoint, data)
            } else {
                this.postData(this.endpoint, data);
            }
        },

        async putData(endpoint, data) {
            try {
                await axios.put(endpoint, data);
                this.succesAlert()
                this.getData();

            } catch (error) {
                this.errorAlert(error);
            }
        },

        async postData(endpoint, data) {
            try {
                let price = await axios.post(endpoint, data);
                let id = price.data[1].links.self_pattern.split("/").pop();
                this.succesAlert()
                this.$router.push("/" + this.endpoint + '/' + id);
                this.getData();

            } catch (error) {
                console.log(error)
                this.errorAlert(error);
            }
        },

        async getData(){
            if(this.$route.params.id){
                this.editionPermission = 'Update_PayrollPostalPrices';
                try {
                    this.loadingData = true
                    var getEndpoint = this.endpoint + "/" + this.$route.params.id;
                    let price = await axios.get(getEndpoint)
                    this.form.code = price.data[0].data.code
                    this.form.price = price.data[0].data.price
                    this.form.addBy = price.data[0].data.addBy
                    this.form.addDate = price.data[0].data.addDate
                    this.form.modBy = price.data[0].data.modBy
                    this.form.modDate = price.data[0].data.modDate

                    this.loadingData = false

                } catch(error){
                    console.log(error)
                    this.loadingData = false
                    this.errorAlert(error);
                }
            }
        },

        enableDisableForm(){
            if(this.$route.params.id){
                this.postalCodeReadonly = true
            }
        },

        deleteRow(){
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(async value => {
                    if(value){
                        try {
                            var id = 'payroll/price/'+this.$route.params.id
                            var response = await axios.delete(id)
                            if(response.status==204){
                                this.$router.push('/payroll/prices/');
                            }
                        } catch (error) {
                            this.errorAlert(error);
                            console.log(error)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        onReset() {
            if(typeof this.$route.params.id === 'undefined'){
                this.form.code = ''
            }
            
            this.form.price = ''
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    created(){
        this.getData()
        this.enableDisableForm()
    }
}
</script>