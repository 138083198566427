import axios from 'axios'
export default {

    methods: {
        async downloadItem(urladdress) {
            await axios({
                url: urladdress,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var filename = response.headers.filename;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                this.loading = false;
            });
        },
        cleanDateTime(datetime){
            var final, time, datetimeArray;
            if(datetime!=null && datetime!=""){
                datetimeArray = datetime.split(" ")
                time = datetimeArray[1].split(":")
                final = datetimeArray[0] + " at " + time[0] + ":" + time[1]
            }
            return final
        }
    }
}
