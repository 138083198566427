export default {

  data() {
    return {
      dismissSecs: 2,
      dismissCountDown: 0,
      alertType: "",

      feedbackMessage: "",
      }
    },

  methods: {
    countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
    },

    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },

    errorAlert(error){
      this.feedbackMessage = "";
      if (typeof error === 'string' || error instanceof String){
        this.feedbackMessage = error
      } else {
        if(Object.prototype.hasOwnProperty.call(error.response.data, "error_msg")){
          error.response.data.error_msg.forEach(element => {
            this.feedbackMessage += element.toString()+"<br>";
          });
        } else {
          this.feedbackMessage = "API error";
        }  
      }
      this.alertType = "danger";
      this.showAlert();
    },

    succesAlert(){
      this.feedbackMessage = "Data saved successfully";
      this.alertType = "success";
      this.showAlert();
    }
  }
};
