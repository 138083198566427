<template>
    <div v-can="'Read_BillingPostalPrices'">
        <div class="m-5">
            <b-container fluid>
                <b-row align-h="start">
                    <b-col class="pl-0 pr-2" md="auto">
                        <h3>Billing Prices</h3>
                    </b-col>
                    <b-col cols="7" class="p-0">
                        <b-button pill variant="primary" size="sm" to="/billing/price/" ref="billingPrice" v-can="'Create_BillingPostalPrices'">
                            <b-icon icon="plus-circle"></b-icon>
                            Add
                        </b-button>
                        <b-button class="ml-2" pill variant="outline-primary" size="sm" to="/home/" ref="home">
                            <b-icon icon="house"></b-icon>
                            Home
                        </b-button>
                        <b-button class="ml-2" pill variant="outline-success" size="sm" @click="downloadItem('billing/price/findAll/excel')">
                            <b-icon icon="cloud-arrow-down-fill"></b-icon>
                            Download
                        </b-button>
                    </b-col>
                    <b-col class="p-0">
                        <b-input-group size="sm" class="mb-2">
                            <b-input-group-prepend is-text>
                                <b-icon icon="search"></b-icon>
                            </b-input-group-prepend>
                            <b-form-input v-model="filter" type="search" placeholder="Search by postal code" @input="loadGridData()"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row>
            </b-container>
            <b-table striped hover :items="billingPostalPrices" :fields="fields" :filter="filter" :per-page="perPage" sort-icon-left :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" @sort-changed="loadGridData()">
                <template #cell(price)="data">
                    {{formatPrice(data.item.price)}}
                </template>
                <template #cell(extra)="data">
                    {{formatPrice(data.item.extra)}}%
                </template>
                <template #cell(ltl)="data">
                    <b-icon v-if="data.item.ltl=='t'" icon="check" variant="primary"></b-icon>
                    <b-icon v-if="data.item.ltl=='f'" icon="x" variant="danger"></b-icon>
                </template>
                <template #cell(edit)="data">
                    <b-button variant="link" :to="data.item.links.self_pattern" ref="user" v-can="'Update_BillingPostalPrices'">
                        <b-icon icon="pencil-square" variant="info"></b-icon>
                    </b-button>
                </template>
                <template #cell(delete)="data">
                    <b-button variant="link" @click="deleteRow(data.item.links.self_pattern)" v-can="'Delete_BillingPostalPrices'">
                        <b-icon icon="x-octagon-fill" variant="danger"></b-icon>
                    </b-button>
                </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="users-table" @input="loadGridData()"></b-pagination>
        </div>
        <div class="fixed-top mt-3 d-flex justify-content-center">
            <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
                {{ feedbackMessage }}
            </b-alert>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Feedback from '../mixins/Feedback.js'
import Basic from '../mixins/Basic.js'

export default {
    name: 'BillingPostalPrices',
    mixins: [Feedback, Basic],
    data() {
        return {
            currentPage: 1,
            rows: 1,
            perPage: 20,
            filter: '',
            users: null,
            sortBy: 'code',
            sortDesc: false,
            billingPostalPrices: [],
            fields: [
                {
                    key: 'code',
                    sortable: true,
                    label: 'Postal Code'
                },
                {
                    key: 'price',
                    sortable: true,
                    label: 'Price (CAD)'
                },
                {
                    key: 'ltl',
                    sortable: true,
                    label: 'LTL'
                },
                {
                    key: 'extra',
                    sortable: false,
                    label: 'Extra (%)'
                },
                {
                    key: 'edit',
                    sortable: false,
                    label: 'Edit'
                },
                {
                    key: 'delete',
                    sortable: false,
                    label: 'Delete'
                }
            ]
        };
    },
    components: {

    },
    methods: {
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        async loadGridData(){
            try {
                var sortDirection = 'asc';
                if(this.sortDesc) sortDirection = 'desc';
                let response = await axios.get('/billing/price?code='+this.filter+'&orderBy='+this.sortBy+','+sortDirection+'&pageNumber='+this.currentPage+'&size='+this.perPage);
                this.billingPostalPrices = response.data.data
                this.rows = response.data.page.totalElements

            } catch (error) {
                this.billingPostalPrices = []
                console.log(error)
            }

        },
        deleteRow(id){
            this.$bvModal.msgBoxConfirm('Are you sure?')
                .then(async value => {
                    if(value){
                        try {
                            var response = await axios.delete(id)
                            if(response.status==204){
                                this.loadGridData()
                                this.succesAlert()
                            }
                        } catch (error) {
                            this.errorAlert(error);
                            console.log(error)
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    },
    created(){
        this.loadGridData()
    }
}
</script>
<style scoped>
.root-bg{
    color: rgb(197, 0, 0);
    font-variant: small-caps;
    letter-spacing: 4.2px;
}

.user-bg{
    color: rgb(0, 144, 180);
    font-variant: small-caps;
    letter-spacing: 4.2px;
}
</style>