<template>
  <div v-can="'Read_PayrollParameters'" class="m-5">
    <div class="fixed-top mt-3 d-flex justify-content-center">
        <b-alert :show="dismissCountDown" dismissible fade :variant="alertType" @dismiss-count-down="countDownChanged">
            {{ feedbackMessage }}
        </b-alert>
    </div>
    <b-container fluid>
        <b-row align-h="start">
            <b-col class="pl-0 pr-2" md="auto">
                <h3>Payroll Parameters</h3>
            </b-col>
            <b-col cols="7" class="p-0">
                <b-button pill variant="outline-primary" size="sm" to="/home" ref="Home">
                    <b-icon icon="house"></b-icon>
                    Home
                </b-button>
            </b-col>
        </b-row>
    </b-container>
    <b-overlay :show="loadingData" rounded="sm">
        <b-form v-if="show">
            <b-form-group label-size="sm" id="input-group-normal-delivery" v-can="'Read_PayrollPostalPrices'">
                Regular Delivery: depends on the <b-link to="/payroll/prices/">postal code prices</b-link>
            </b-form-group>
            <b-card class="mt-3" header="Special Orders">
                <b-container fluid>
                    <b-row>
                        <b-col>
                            <b-form-group id="input-group-flyBy" label="Fly by (CAD):" label-for="input-flyBy" description="Fly by is mandatory." v-can:number="'Update_PayrollParameters'">
                                <b-form-input id="input-flyBy" v-model="form.flyBy" type="number" placeholder="Enter fly by" :state="validateState('flyBy')" aria-describedby="flyBy-live-feedback"></b-form-input>
                                <b-form-invalid-feedback id="flyBy-live-feedback">This is a required field.</b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-container>
            </b-card>
            <b-card class="mt-3" header="Service Level">
                <b-container fluid>
                    <b-row>
                        <b-col>
                            <b-form-group id="input-group-floor3" label="3rd floor (CAD):" label-for="input-floor3" description="3rd floor is mandatory." v-can:number="'Update_PayrollParameters'">
                                <b-form-input id="input-floor3" v-model="form.floor3" type="number" placeholder="Enter 3rd floor" :state="validateState('floor3')" aria-describedby="floor3-live-feedback"></b-form-input>
                                <b-form-invalid-feedback id="floor3-live-feedback">This is a required field.</b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group id="input-group-floor4" label="4th floor (CAD):" label-for="input-floor4" description="4th floor is mandatory." v-can:number="'Update_PayrollParameters'">
                                <b-form-input id="input-floor4" v-model="form.floor4" type="number" placeholder="Enter 4th floor" :state="validateState('floor4')" aria-describedby="floor4-live-feedback"></b-form-input>
                                <b-form-invalid-feedback id="floor4-live-feedback">This is a required field.</b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group id="input-group-roomOfChoice" label="Room of choice (CAD):" label-for="input-roomOfChoice" description="Room of choice is mandatory." v-can:number="'Update_PayrollParameters'">
                                <b-form-input id="input-roomOfChoice" v-model="form.roomOfChoice" type="number" placeholder="Enter room of choice" :state="validateState('roomOfChoice')" aria-describedby="roomOfChoice-live-feedback"></b-form-input>
                                <b-form-invalid-feedback id="roomOfChoice-live-feedback">This is a required field.</b-form-invalid-feedback>
                            </b-form-group>
                        </b-col>
                        <b-col>
                        </b-col>
                    </b-row>
                </b-container>
            </b-card>

            <b-card bg-variant="light" sub-title="History" class="mt-3" v-if="!loading">
                <History :hideCreation="true" :dataType="endpoint" :addBy="form.addBy" :addDate="form.addDate" :lastModBy="form.modBy" :lastMod="form.modDate" />
            </b-card>

            <b-container class="mt-4" fluid>
                <b-row>
                    <b-col>
                        <b-overlay :show="loading" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block" @hidden="onHidden">
                            <b-button class="mr-2" type="button" variant="primary" :disabled="loading" ref="button" @click.prevent="onSubmit()">
                                <b-icon icon="file-earmark"></b-icon>
                                Save
                            </b-button>
                        </b-overlay>
                        <b-button variant="outline-primary" to="/home/" ref="home">
                            <b-icon icon="arrow-return-left"></b-icon>
                            Go Back
                        </b-button>
                    </b-col>
                    <b-col class="text-right">
                        <b-button class="mr-2" type="reset" variant="outline-danger" @click.prevent="onReset()">
                            <b-icon icon="arrow-counterclockwise"></b-icon>
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-form>
    </b-overlay>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import Feedback from '../mixins/Feedback.js'
import History from '../components/History.vue'
export default {
    mixins: [Feedback],
    components: {
        History
    },
    validations() {
        return {
            form: {
                roomOfChoice: {
                    required
                },
                floor3: {
                    required
                },
                floor4: {
                    required
                },
                flyBy: {
                    required
                },
            }
        }
    },
    data() {
        return {
            form: {
                roomOfChoice: 0,
                floor3: 0,
                floor4: 0,
                flyBy: 0,
                addBy: '',
                addDate: '',
                modBy: '',
                modDate: ''
            },
            postalCodeReadonly: false,
            show: true,
            loading: false,
            endpoint: 'payroll/parameter',
            loadingData: false
        }
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        onHidden() {
            this.$refs.button.focus()
        },
        async onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            this.loading = true
            await this.processForm()
            this.loading = false
        },

        async processForm(){
            let data = this.form
            var putEndpoint = this.endpoint;
            this.putData(putEndpoint, data)
        },

        async putData(endpoint, data) {
            try {
                await axios.put(endpoint, data);
                this.succesAlert()
                this.getData();

            } catch (error) {
                this.errorAlert(error);
            }
        },

        async getData(){
            try {
                this.loadingData = true
                var getEndpoint = this.endpoint;
                let price = await axios.get(getEndpoint)
                this.form.roomOfChoice = price.data[0].data.roomOfChoice
                this.form.floor3 = price.data[0].data.floor3
                this.form.floor4 = price.data[0].data.floor4
                this.form.flyBy = price.data[0].data.flyBy
                this.form.addBy = price.data[0].data.addBy
                this.form.addDate = price.data[0].data.addDate
                this.form.modBy = price.data[0].data.modBy
                this.form.modDate = price.data[0].data.modDate
                this.loadingData = false

            } catch(error){
                console.log(error)
                this.loadingData = false
                this.errorAlert(error);
            }
        },

        enableDisableForm(){

        },

        onReset() {
            
            this.form.roomOfChoice = 0
            this.form.floor3 = 0
            this.form.floor4 = 0
            this.form.flyBy = 0
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        }
    },
    created(){
        this.getData()
        this.enableDisableForm()
    }
}
</script>